import { Component } from "react";

import {districtsCoreContractInstance , narcoCoreContractInstance, getWeb3} from "./Contracts"
import {CURRENCY_SYMBOL} from "../GlobalConfig"

import districts from './districts_POA.json';
import districtItems from './items.json';

import {narco} from './NarcoStateWatch'


var sellSpreadPercent=10;
var endCooldownPrice=15;
var airLiftPrice=40;
var travelPrice=2;
var hijackPrice=10;
var nearNarcos = [];

var saleDangerZone=30;

const web3 = getWeb3();

const getDistrict=(n)=>{
  //console.log(n);
  const num = parseInt(n);
  districtsCoreContractInstance().getDistrict(num)
  .call({},(err, result) => {

    //console.log(num);
   // console.log(result)
    if(result != null){
      var mps = result[2];
      districts[num-1].marketPrices[0] = parseFloat(web3.utils.fromWei(mps[0], 'ether'));
      districts[num-1].marketPrices[1] = parseFloat(web3.utils.fromWei(mps[1], 'ether'));
      districts[num-1].weedPot=parseFloat(web3.utils.fromWei(result[4], 'ether'));
      districts[num-1].cokePot=parseFloat(web3.utils.fromWei(result[5], 'ether'));
      districts[num-1].weedAmountHere=parseInt(result[6]);
      districts[num-1].cokeAmountHere=parseInt(result[7]);
    }

  })

  districtsCoreContractInstance().numberOfNarcosByDistrict(num)
  .call({},(err, result) => {

    if(result != null){
      districts[num-1].population = parseInt(result);
    }

  })

 }

export class DistrictsWatch extends Component {

  static instance = null;


  constructor(props) {
    super(props);
  }

 componentWillMount(){
        DistrictsWatch.instance=this;
        this.updateVariables();
        this.updatePricing();

        // Is 10 seconds about right?
     //   setInterval(this.updatePricing.bind(this), 6000)
       // setInterval(this.updateVariables.bind(this), 30000);
  }

  updateVariables = async()=>{

    // get contract spreadPercent
    /*
    let result = districtsCoreContractInstance().spreadPercent().call();

    if(result != null){
        sellSpreadPercent = parseInt(result);
        }*/

    // get contract travel price
    let result = await districtsCoreContractInstance().travelPrice().call();

    if(result != null){
        travelPrice = parseFloat(web3.utils.fromWei(result, "ether"));
        }

    // get contract travel price
    result = await districtsCoreContractInstance().saleDangerZone().call();

    if(result != null){
        saleDangerZone = parseInt(result);
        }

    // get contract travel price
    result = await districtsCoreContractInstance().airLiftPrice().call();

    if(result != null){
        airLiftPrice = parseFloat(web3.utils.fromWei(result));
        }

    // get contract hijackPrice price
    result = await districtsCoreContractInstance().hijackPrice().call();

    if(result != null){
        hijackPrice = parseFloat(web3.utils.fromWei(result));
        }

    // get contract hijackPrice price
    result = await districtsCoreContractInstance().endCoolDownPrice().call();

    if(result != null){
        endCooldownPrice = parseFloat(web3.utils.fromWei(result));
        }

  }

   updatePricing = async()=>{
   

    getDistrict(1);
    getDistrict(2);
    getDistrict(3);
    getDistrict(4);
    getDistrict(5);
    getDistrict(6);
    getDistrict(7);
   // getDistrict(8);

   }

   render(){return null}

}


export function getMarketItems(districtNumber) {

//console.log("getMarketItems =" + districtNumber);

  districtNumber-=1;
  var marketItems = [];
  for (var i=0;i<districtItems.length;i++){
//    console.log(marketItemNames[i]);
  //  console.log(districtsConfig[districtNumber].isStocked[i]);

      if (districtsState[districtNumber].isStocked[i]==true){
        var item = {
          id: districtItems[i].id,
          name: districtItems[i].name,
          price: districtsState[districtNumber].marketPrices[i],
          skillAffected: districtItems[i].skillAffected,
          skillNumber: districtItems[i].skillNumber,
          upgrade: districtItems[i].upgrade,
          levelRequired: districtItems[i].levelRequired,
          skillRequired: 0
        };

        if (i==0){
          item.sellPrice = districts[districtNumber].weedPot/districts[districtNumber].weedAmountHere;
        }
        if (i==1){
          item.sellPrice = districts[districtNumber].cokePot/districts[districtNumber].cokeAmountHere;
        }


        // requires skill
        if (districtItems[i].skillNumber!=5) {
          if (item.upgrade==20){
              item.skillRequired=10; // must have purchased earlier item
          }
          if (item.upgrade==40){
              item.skillRequired=30; // must have purchased earlier item
          }
        }else{
          // special case for capacity
          if (item.upgrade==40){
              item.skillRequired=50; // must have purchased earlier item
          }
          if (item.upgrade==80){
              item.skillRequired=90; // must have purchased earlier item
          }
        }

      //  console.log(item);

        marketItems.push(item);
      }
  }
  return marketItems;
}
export var airLiftCost = airLiftPrice;
export var travelCost = travelPrice;
export var hijackCost = hijackPrice;
export var endCooldownCost = endCooldownPrice;
export var nearbyNarcos = nearNarcos;
export var saleDangerZonePercent = saleDangerZone;
export const districtsState  = districts;
