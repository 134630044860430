
import React, { Component } from 'react';

import LocalizedStrings from 'react-localization';

export var strings = new LocalizedStrings({
  en:{

    help:"Help",

    weed: "weed",
    coke: "coke",

    districtone:"Jamaica Village",
    districttwo:"Novo Moskovo",
    districtthree:"Little Italy",
    districtfour:"Cartagena",
    districtfive:"Baltimore",
    districtsix:"Chinatown",
    districtseven:"Vice Island",

    districtNames: ["Jamaica Village","Novo Moskovo","Little Italy","Cartagena","Baltimore","Chinatown","Vice Island"],

    m:"Market",
    d:"Districts",
    p:"Production",
    r:"Raiding",
    i:"Inventory",
    e:"Events",
    l:"Leaderboard",

    gw:"Grow weed",
    gs:"Grow skill",
    ps:"Lab skill",
    my:"Max Yield",
    cost:"Cost",
    clean:"Cleaning up grow room:",
    togrow:"To grow you need seeds and to be in home location.",
    seeds:"Seeds",
    ounces:"ounces",

    proc:"Produce coke",
    chems:"Chems",
    toprod:"To produce you need chemicals and to be in home location.",

    welcome : ["Welcome to Jamaica Village.","You are in Novo Moskovo" , "Benvenuto a Little Italy"  , "Bienvenido a Cartagena" , "Welcome to Baltimore" , "Chinatown" , "Vice Island - Home of Office Lardass"],

    welcomeText: [
      "I'm Benny 'The Botanist' Green and welcome to Little Jamaica.  We sell only the finest seeds and herb here, all completely vegan friendly, gluten free and containing none of those toxic GMOs! It’s always sunny in Little Jamaica, so pop down to the beach, turn up the reggae and light up! Remember though, just because we preach love and peace, doesn’t mean we don’t know how to protect ourselves…",
      "I'm Boris 'Bearface' Smyrnov and this is my junkyard. I have the best vehicles and gas available anywhere - so load up! I'll give you a good deal. If you get your jollies hijacking, I suggest you hit every American you find here. Well. What are you waiting for? ",
      "I'm sure you've heard of La Diva Esposita. This is where to find grow gear to increase your weed production, and luggage so you can smuggle more. LIttle Italy is a popular place, so take care around town or you might end up getting whacked by some of the locals. The quicker you get your deals done around here, the better.",
      "Don't mess with El Cabo. Cartagena is muy peligroso for a novice Narco to be hanging around. We produce the best cocaine here and lots of it, ready for export to other districts. We’ll hook you up for a good price - if you make it over the border, you can keep the profits! The locals here won't think twice about getting nasty, so best be on your way…",
      "I'm Leonard 'Smokey' West and I run this neighbourhood. If you're looking for guns or ammunition, I'm your guy. You'll find a decent range of pieces and unlimited supply of bullets, but as usual there's a price. Just don't start thinking you can take liberties around here. Narcos from this district are well equipped and won't put up with outsiders playing on their turf. So just chill and everything gonna be ok.",
      "Like what you see? I'm Fan 'Fun' Lee, and will do business with anybody. Get the best laboratory equipment here to increase your cocaine processing skills. We also supply very decent bullet vests and other protection. This is just the stuff you will need to make a living in this world. Don't buy any of those cheap fakes you find in the Black Market!",
      "Lardass is a dirty cop. Can't you tell? The black market here has no limits. You can buy items even if you don't have the respect you need elsewhere. A lot of Narcos hang around here, so take care. You can take your chances raiding Officer Lardass here, but good luck with that!",
   ],

    // inventory

    attack:"Attack",
    defense:"Defense",
    grow:"Grow",
    carry:"Carry",
    produce:"Produce",
    speed:"Speed",

    inventory:"Inventory",

    items: ["Weed","Cocaine","Gas","Seeds","Chemicals","Ammo",
    "Classic Street Rod","Street Mod BMW","Lambo",
    "Gardening Gloves","Weed Barrow","Hydro Kit",
    "Lab Mask","Lab Burner","Lab Gear",
    "Pistol","Semi-Auto","Khyber Pass AK47",
    "Disguise","Bullet Vest","Kevlar Vest",
    "Utility Belt","Belt Pack","Super Back Pack"
    ],

    youhave:"You have",
    travelcost:"Travel cost",
    canttravel:"Can't travel without gas!",
    looking:"Looking for parking:",
    identifying:"Identifying",
    cleaning:"Cleaning up evidence",
    population:"Population",

    buy:"BUY",
    sell:"SELL",
    travel: "Travel",

    leaderboard:"Leaderboard",
    rank:"Rank",
    name:"Name",
    home:"Home",
    respect:"Respect",
    activity:"Activity",

    grams:"Grams",
    gallons:"Gallons",
    consumables:"Consumables",
    badges:"Badges",
    price:"PRICE",
    ppo:"Price per ounce",
    back:"BACK",
    needrespect:"Need respect",
    purchased:"Purchased",
    ammo:"Ammo",

    weedsupply:"Weed supply",
    cokesupply:"Coke supply",

    weedprices:"Weed prices",
    cokeprices:"Coke prices",

    narco:"Narco",

    cokeReady: "Your batch of coke is ready! It can be sold immediately.",
    weedReady: "Your crop of weed is ready! It can be sold immediately.",

    saleComplete: "Your sale is complete!",
    purchaseComplete: "Your purchase is complete!",

    purchaseFailed: "Purchase failed (price changed), no POA was deducted.",

    eventCategories: ['WOKE UP', 'RAIDED', 'ARRIVED', 'BUSTED', 'ESCAPED'],
    events: "events",

    XXXjustArrivedYYY: " has just arrived at ",

    bustedByOfficerLardass: " was busted by Officer Lardass！ ",
    weedTaken: " oz weed ",
    cokeConfiscated: "  oz coke confiscated!! ",

    luckyEscape: "Lucky Escape!!",


    successfullyDefended1: " successfully defended a raid by ",
    successfullyDefended2: "",

    maybeNextTime: ", maybe next time!!",

    raidedBy: " was raided by ",
    stolenOuch: " was stolen. Ouch!!",

    nothingStolen: " But nothing was stolen. Phew!"

  },


  ch: {

    help:"帮助",


    weed: "Weed",
    coke: "Coke",


    districtone:"牙买加村",
    districttwo:"诺夫莫斯科沃",
    districtthree:"小意大利",
    districtfour:"卡塔赫纳",
    districtfive:"巴尔的摩",
    districtsix:"唐人街",
    districtseven:"罪恶之屿",

    districtNames: ["牙买加村","诺夫莫斯科沃","小意大利","卡塔赫纳","巴尔的摩","唐人街","罪恶之屿"],


    m:"市场",
    d:"区域",
    p:"产量",
    r:"打劫",
    i:"装备",
    e:"事件",
    l:"排行榜",

    gw:"种植大麻",
    gs:"种植技能",
    ps:"生产技能",
    my:"最高产量",
    cost:"花费",
    clean:"你需要拥有种子且处于主区内才能进行种植:",
    togrow:"你需要拥有种子且处于主区内才能进行种植",
    seeds:"种子",
    ounces:"盎司",

    proc:"生产可卡因",
    chems:"化学品",
    toprod:"你需要拥有化学品且处于主区内才能进行生产",


    welcome : ["欢迎来到牙买加村","你现在位于诺夫莫斯科沃" , "欢迎来到小意大利"  , "欢迎来到卡塔赫纳" , "欢迎来到巴尔的摩" , "唐人街" , "罪恶之屿-胖子警官的主区"],

    welcomeText: [
      "我叫Benny，是一名植物学家。在这里你能找到最棒的种子。 不过要小心胖子警官。他可是会突袭并没收毒品。",
      "我是Boris，他们都叫我大胡子。这里是我的废旧车场。我能为你提供最好的车辆和汽油。我保证价格公道。如果你喜欢劫车的话，我建议你对每个路过的美国人都试试。还等什么，快上车！",
      "我相信你一定听说过La Diva。 在这里你能找到提高大麻产量的种植用具，以及偷运用的装运箱。小意大利是个很受欢迎的地方。所以一定要小心，否则你有可能被当地人欺负。 完成交易就离开，越快越好。",
      "卡塔赫纳对新手毒枭来说不是一个友好之地。这里生产大量的可卡因，并随时准备销往其他区域。很多人都选择在这里低价买入再到别的地方高价卖出。这里的当地可没有这么好惹。千万不要逗留。",
      "我是Leonard，外号大烟枪。这里的街区都归我管。如果你要买枪支弹药，找我就对了。只要给钱，枪随便挑，子弹管够。不过可别以为你可以在这里乱来。这里的毒枭个个装备齐全，脾气冲。所以千万别惹事。",
      "我是Fan, 叫我小可爱就好。我跟这里的每一个人都打过交道。在这里你能买到实验室器材来提高你的可卡因产量。当然你要防备背心或者其他护具也可以找我。这些可是生存的必须品喔。可千万别去买黑市上的假货。",
      "Lardass是一名不择手段的黑警官。你难道看不出来吗？这里的黑市没有任何限制。你能买到所有你想买的东西，即使声望不够。当然，正是因此，毒枭们都聚集于此。所以千万当心。如果你想碰碰运气，可以尝试向胖子警官发起掠夺。祝你好运！",
    ],

    inventory:"装备",
    attack:"攻击",
    defense:"防御",
    grow:"种植技能",
    carry:"负重",
    produce:"生产技能",
    speed:"速度",

    items:["Weed","Cocaine",
    "燃料","种子","化学品","弹药",
    "老式汽车","宝马","兰博基尼",
    "园丁手套","推车","水利设备",
    "实验室面具","实验室烧瓶","实验室器材",
    "手枪","半自动步枪","AK47步枪",
    "伪装","防弹背心","凯芙拉背心",
    "实用腰带","腰包","超级背包"
    ],

    youhave:"拥有",
    travelcost:"游走花费",
    canttravel:"燃料不足，无法进行游走",
    looking:"正在找停车位:",
    identifying:"变更中",
    cleaning:"销魂证据中",
    population:"人口",

    buy:"购买",
    sell:"出售",
    travel: "前往",

    leaderboard:"排行榜",
    rank:"秩",
    name:"名字",
    home:"主区",
    respect:"声望",
    activity:"活动",

    grams:"克",
    gallons:"加仑",
    consumables:"耗材",
    badges:"徽章",
    price:"价钱",
    ppo:"每盎司的价格",
    back:"背部",
    needrespect:"需要尊重",
    purchased:"购买",
    ammo:"弹药",

    weedsupply:"Weed Supply",
    cokesupply:"Coke Supply",

    weedprices:"Weed Prices",
    cokeprices:"Coke Prices",

    narco:"毒枭",

    cokeReady: "可卡因生产完成!",
    weedReady: "大麻种植完成",

    saleComplete: "Y成功出售！",
    purchaseComplete: "成功购买！",

    purchaseFailed: " 买不成功 ",


    eventCategories: ["清醒", "掠夺", "抵达", "逮捕", "逃跑"],
    events: "事件",

    XXXjustArrivedYYY: " 刚刚抵达了 ",

    bustedByOfficerLardass: " 被胖子警官逮捕！ ",

    weedTaken: " 大麻和 ",
    cokeConfiscated: "  可卡因被没收！",

    luckyEscape: " 幸运逃脱！！",

    successfullyDefended1: " 成功抵御了 ",
    successfullyDefended2: " 的洗劫 ",
    maybeNextTime: " 下次好运! ",
    raidedBy: " 被 ",
    stolenOuch: " 被夺走！",
    nothingStolen: " 还好什么都没被抢！"

  },

de: {
help:"Hilfe",

weed: "Weed",
coke: "Coke",

districtone:"Jamaica Village",
districttwo:"Novo Moskovo",
districtthree:"Little Italy",
districtfour:"Cartagena",
districtfive:"Baltimore",
districtsix:"Chinatown",
districtseven:"Vice Island",

districtNames: ["Jamaica Village","Novo Moskovo","Little Italy","Cartagena","Baltimore","Chinatown","Vice Island"],

m:"Markt",
d:"Bezirke",
p:"Produktion",
r:"Überfälle",
i:"Inventar",
e:"Events",
l:"Bestenliste",

gw:"Weed anbauen",
gs:"Anbau-Fertigkeit",
ps:"Labor-Fertigkeit",
my:"Maximale Ausbeute",
cost:"Kosten",
clean:"Gewächshaus aufräumen:",
togrow:"Um Pflanzen anzubauen, musst du Samen besitzen und bei dir Zuhause sein.",
seeds:"Samen",
ounces:"Unzen",

proc:"Coke kochen",
chems:"Chemikalien",
toprod:"Zum Kochen benötigst du Chemikalien und musst bei dir Zuhause sein.",

welcome : ["Willkommen in Jamaica Village.","Du bist in Novo Moskovo" , "Benvenuto a Little Italy" , "Bienvenido a Cartagena" , "Willkommen in Baltimore" , "Chinatown" , "Vice Island - Die Hochburg von Officer Lardass"],

welcomeText: [
"Ich bin Benny 'Der Botaniker' Green und willkommen in Little Jamaica. Wir verkaufen hier nur die feinsten Samen und Kräuter, alles vollkommen vegan, gluten-frei und beinhalten keine dieser giftigen Gen-Modifizierungen! Es ist immer sonnig in Little Jamaica, also schlender zum Strand, dreh den Reggae auf und mach dir einen an! Aber denk dran, nur weil wir Liebe und Frieden predigen, heisst das nicht, dass wir nicht wissen wie wir uns selbst verteidigen können...",
"Ich bin Boris 'das Bärengesicht' Smyrnov und das ist mein Schrottplatz. Ich habe die besten Karossen und Benzin zur Verfügung, die du irgendwo finden kannst - also mach voll! Ich mach dir nen guten Preis. Wenn du deinen Spaß an Überfällen hast, schlage ich vor du nimmst dir jeden Amerikaner zur Brust, den du hier findest. Also dann, auf was wartest du? ",
"Ich bin sicher du hast von La Diva Esposita gehört. Hier findest du Ausrüstung für den Anbau von Weed, um deine Produktion zu erhöhen und Gepäck, dass du mehr schmuggeln kannst. Little Italy ist ein berühmtes Plätzchen, also pass auf in der Gegend um die Stadt oder du findest dich in einer Situation wieder umringt von Einheimischen. Je schneller du hier dein Zeugs erledigst, desto besser.",
"Leg dich nicht mit El Cabo an. Cartagena ist muy peligroso zum Rumhängen für nen Anfänger-Narco. Wir kochen hier das beste Coke in Massen, fertig zum Export in andere Bezirke. Wir geben dir nen netten Deal - wenn du's über die Grenze schaffst, kannst du den Gewinn behalten! Die Einheimischen hier werden nicht zweimal überlegen, ungemütlich zu werden, also gehst du besser deines Weges...",
"Ich bin Leonard 'Der Rauchige' West und ich leite hier die Nachbarschaft. Wenn du nach Waffen und Munition suchst, bin ich der Richtige. Du wirst hier eine anständige Auswahl und eine unendliche Versorgung an Munition vorfinden, aber wie immer hat das seinen Preis. Glaub aber bloß nmicht du kannst dir hier deine Freiheiten rausnehmen. Narcos von hier sind bis an die Zähne bewaffnet, und lassen sich von Außenseitern nicht ihr Revier streitig machen. Also entspann dich und alles wird gut.",
"Gefällt dir was du siehst? Mein Name ist Fan 'Spaß' Lee, und ich mache mit jedem Geschäfte. Hol die hier das Beste an Laborausrüstung, um deine Kochfertigkeiten zu verfeinern. Wir versorgen dich auch mit ansehnlichen schussicheren Vesten und anderem Schutz. Das ist genau das Zeug, dass du brauchen wirst, um dir dein Überleben in dieser Welt zu sichern. Kauf dir lieber nichts von dem billigen Scheiss, den du auf dem Schwarzmarkt findest!",
"Lardass ist en korrupter Cop. Ist eindeutig oder? Der Schwarzmarkt hier ist grenzenlos. Kannst dir hier alles kaufen, selbst wenn du nichtmal den Respekt hast, den du sonstwo bräuchtest. Hier hängen ne Menge Narcos rum, also pass auf dich auf. Hier kannst du die Chance beim Schopf packen und Lardass eine reinwürgen, aber viel Glück damit!",
],

// inventory

attack:"Angriff",
defense:"Verteidigung",
grow:"Anbau",
carry:"Tragekapazität",
produce:"Kochen",
speed:"Geschwindigkeit",

inventory:"Inventar",

items: ["Weed","Coke","Benzin","Samen","Chemikalien","Munition",
"Klassische Straßenkutsche","Modifizierter BMW","Lambo",
"Gartenhandschuhe","Schubkarre","Bewässerungsanlage",
"Labormaske","Bunzenbrenner","Laborausrüstung",
"Pistole","Halb-Automatik","Khyber Pass AK47",
"Tarnmaske","Kugelsichere Veste","Kevlar Veste",
"Multifunktionsgürtel","Bauchtasche","Großer Rucksack"
],

youhave:"Du hast",
travelcost:"Reisekosten",
canttravel:"Du kannst ohne Benzin nicht reisen!",
looking:"Auf der Suche nach einem Parkplatz:",
identifying:"Identifizieren",
cleaning:"Beweise beseitigen",
population:"Einwohner",

buy:"Kaufen",
sell:"Verkaufen",
travel: "Reisen",

leaderboard:"Bestenliste",
rank:"Rang",
name:"Name",
home:"Heimat",
respect:"Respekt",
activity:"Activität",

grams:"Gramm",
gallons:"Gallonen",
consumables:"Verbrauchsgegenstände",
badges:"Abzeichen",
price:"Preis",
ppo:"Preis pro Unze",
back:"Zurück",
needrespect:"benötigter Respekt",
purchased:"Gekauft",
ammo:"Munition",

weedsupply:"Weed Vorrat",
cokesupply:"Coke Vorrat",

weedprices:"Weed Preis",
cokeprices:"Coke Preis",

narco:"Narco",

cokeReady: "Deine Ladung Coke ist fertig zum Verkauf!",
weedReady: "Deine Ladung Weed ist fertig zum Verkauf!",

saleComplete: "Dein Handel ist abgeschlossen!",
purchaseComplete: "Dein Einkauf ist abgeschlossen!",

purchaseFailed: "Einkauf fehlgeschlagen (Preis hat sich geändert), kein POA wurde abgezogen.",

eventCategories: ['AUFGEWACHT', 'ÜBERFALLEN', 'ANGEKOMMEN', 'ERWISCHT', 'ENTKOMMEN'],
events: "Ereignisse",

XXXjustArrivedYYY: " ist gerade angekommen in ",

bustedByOfficerLardass: " ist von Officer Lardass erwischt worden！ ",
weedTaken: " oz Weed ",
cokeConfiscated: " oz Coke konfisziert!! ",

luckyEscape: "Gerade so entwischt!!",


successfullyDefended1: " hat erfolgreich einen Überfall vereitelt von ",
successfullyDefended2: "",

maybeNextTime: ", vielleicht nächstes Mal!!",

raidedBy: " wurde überfallen von ",
stolenOuch: " wurde entwendet. Ouch!!",

nothingStolen: " Aber nichts wurde entwendet. Puh!"

},



ru:{

  help:"Помощь",

  weed: "травка",
  coke: "кокс",

  districtone:"Ямайская деревенька",
  districttwo:"Новая Москва",
  districtthree:"Маленькая Италия",
  districtfour:"Картахена",
  districtfive:"Балтимор",
  districtsix:"Чайнатаун",
  districtseven:"Остров Порока",

  districtNames: ["Ямайская деревенька","Новая Москва","Маленькая Италия","Картахена","Балтимор","Чайнатаун","Остров Порока"],

  m:"Рынок",
  d:"Районы",
  p:"Производство",
  r:"Налеты",
  i:"Инвентарь",
  e:"События",
  l:"Таблица лидеров",

  gw:"Вырастить ганджу",
  gs:"Скилы ботаника",
  ps:"Мощность лаборатории",
  my:"Максимальный выхлоп",
  cost:"Затраты",
  clean:"Зачистка хаты:",
  togrow:"Для выращивания тебе нужны семена и ты должен быть в своем районе.",
  seeds:"Семена",
  ounces:"унций",

  proc:"Синтез кокса",
  chems:"Химия",
  toprod:"Для выращивания тебе нужна химия и ты должен быть в своем районе.",

  welcome : ["Вэлкам в Ямайскую деревеньку","Ты в Новой Москве" , "Benvenuto a Little Italy" , "Bienvenido a Cartagena" , "Вэлкам ту Baltimore" , "Это Чайнатаун" , "Остров Порока - Район под офицером Лардасс"],

  welcomeText: [
  "Я Зелёный Бенни 'Ботаник' и добро пожаловать к нам на Ямайскую деревеньку, Бро. Тут мы банчим только лучшими семенами и ганджубасом, всё чисто веганское, без глютена, усилителей вкуса и не содержит ГМО! Здесь всегда солнечно и в нашей деревеньке всегда играет муза, слушай регги и зажигай! Но запомни Бро, хоть мы и проповедуем любовь и мир, это не значит, что мы не знаем, как защитить себя ...",
  "Я Борис Смирнов 'Медвежъе рыло' и это мой авторазбор. Только у меня ты найдешь лучшие тачки и дешёвый бензин, так что закупаться этим добром полюбому здесь! У меня для тебя есть маза. Если ты реальный пацан, то можешь хопнуть любого пиндоса, которого тут встретишь! Чё стоим, кого ждём?",
  "Я уверен, что ты слышал о La Diva Esposita. Здесь ты сможешь найти любое оборудование, чтобы выращивать больше ганджи, а снаряжение для перевозки контрабанды лучшее в мире, от именитых итальянских дизайнеров. Маленькая Италия - популярное место, поэтому не надо косячить в городе или тебя легко хопнет кто-нибудь из местных. Чем быстрее ты скинешь товар, тем для тебя же и лучше.",
  "Не связывайтесь с Эль-Кабо. Картахена - это стремное место для барыги-новичка, которые тут часто ошиваются. Здесь мы производим лучший кокс, белый как снег и чистый как слеза младенца. У нас его много, уже упакованного и готового для экспорта и за хорошую цену. Если ты сможешь переправить его через границу, то получишь хороший навар! Местные здесь не будут думать дважды о том, чтобы грабануть тебя, так что лучше бери товар и сваливай побыстрее!",
  "Я Леонард Вест 'Копчённый', и я рулю на этом районе. Если ты ищешь оружие или боеприпасы, я твой корешь. Ты найдешь у меня приличный ассортимент и неограниченное количество пуль, но, как обычно, за всё надо платить. Даже не думай, что ты сможешь почудить здесь. Барыги из этого района нехило заряжены и быстро прихлопнут любого, кто начнет мутить в их болоте. Так что просто не кипишуй и все будет в порядке.",
  "Что вы видите? Я Фан Ли 'Шутник' и я веду бизнес со всеми. Получите лучшее лабораторное оборудование здесь, чтобы кокаин обработка навык круто быть. Мы также продать очень лучший пулевая жилетка и другую защищайся. Вам нужно будет зарабатывать на жизнь в этом мире. Не покупайте ни одну из этих дешевых подделок, которые вы видеть интернет-заказ и на Черном рынок!",
  "Лардасс - грязный коп. Чё тебе сказать? Черный рынок здесь безграничен. Ты можешь купить всё, что душе угодно, даже если у тебя нет Уважухи, которая нужна для торговли в других местах. Ты можешь рискнуть своим здоровьем и грабануть офицера Лардасс, может и проканает, Удачи!!",
  ],

  // inventory

  attack:"Атака",
  defense:"Оборона",
  grow:"Выращивание",
  carry:"Причиндалы",
  produce:"Синтез",
  speed:"Скорость",

  inventory:"Инвентарь",

  items: ["Ганджа","Кокс","Бензин","Семена","Химикаты","Боезапасы",
  "Классика","Бэха","Ламбо",
  "Садовые перчатки","Огородная тачка","Гидропоника",
  "Лабораторная маска","Химическая горелка","Лабораторное оборудование",
  "Макар","Стечкин","Калаш",
  "Маска","Броник","Кевларовый броник",
  "Пояс","Бананка","Рюкзак"
  ],

  youhave:"У тебя",
  travelcost:"За проезд",
  canttravel:"Толкать будешь или бензину зальешь?!",
  looking:"Ищу парковку:",
  identifying:"Пробиваем",																									
  cleaning:"Зачищаем хату",
  population:"Население",

  buy:"КУПИ",
  sell:"ПРОДАЙ",
  travel: "Поездка",

  leaderboard:"Лидеры",
  rank:"Ранг",
  name:"Погоняло",
  home:"Район",
  respect:"Уважуха",
  activity:"Активность",

  grams:"Грамм",
  gallons:"Литров",
  consumables:"Расходники",
  badges:"Бэйджи",
  price:"ЦЕНА",
  ppo:"Цена за унцию",
  back:"ВЗАД",
  needrespect:"Нужна уважуха",
  purchased:"Накупил",
  ammo:"Боезапас",

  weedsupply:"Ганджи на районе",
  cokesupply:"Кокса на районе",

  weedprices:"Цены на ганджу",
  cokeprices:"Цены на кокс",

  narco:"Барыга",

  cokeReady: "Ваша партия кокса готова! Можно прям щас скинуть!",
  weedReady: "Ваша партия ганджи готова! Сливай, пока не прокинули!",

  saleComplete: "Удачно продал!",
  purchaseComplete: "Норм закуп!",

  purchaseFailed: "Покупка сорвалась (цены поменяли), POA не потрачены.",

  eventCategories: ['ОЧНУЛСЯ', 'ОГРАБЛЕН', 'ПРИБЫЛ', 'АРЕСТОВАН', 'СБЕЖАЛ'],
  events: "движуха",

  XXXjustArrivedYYY: " только что засветился в  ",

  bustedByOfficerLardass: " был арестован офицером Лардасс！ ",
  weedTaken: " унций ганджи ",
  cokeConfiscated: " унций кокса конфисковано!! ",

  luckyEscape: "Проскочил без палева!!",


  successfullyDefended1: " успешно отбился от ",
  successfullyDefended2: "",

  maybeNextTime: ", может в другой раз!!",

  raidedBy: " был перехвачен ",
  stolenOuch: " было отобрано. Сцуко!!",

  nothingStolen: " Но никуя не отдал. Заибцо!"

  },


 });

export function langStrings(){
  return strings;
}

 export function setLanguage(language){
     strings.setLanguage(language);
 }

 export function getLanguage(){
     return strings.getLanguage();
 }
