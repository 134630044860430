
export const actionAnimations =

[
  {
    "type": "BuyingWeed",
    "phrase": "Gimmie Gimmie Gimmie!",
    "image": "http://i.perezhilton.com/wp-content/uploads/2017/07/weed-emergency.gif"
  },
  {
    "type": "BuyingCoke",
    "phrase": "What's in the bag man?",
    "image": "https://media3.giphy.com/media/SnwifA7bOFDhe/giphy.gif"
  },
  {
    "type": "GrowingWeed",
    "phrase": "It's gluten free.",
    "image": "images/growing-weed-animation.gif"
  },
  {
    "type": "NotGrowingWeed",
    "phrase": "It's gluten free.",
    "image": "images/not-growing-weed.png"
  },
  {
    "type": "ProcessingCoke",
    "phrase": "Vamos a hacerlo!",
    "image": "images/processing-coke-animation.gif"
  },
  {
    "type": "NotProcessingCoke",
    "phrase": "Vamos a hacerlo!",
    "image": "images/not-processing-coke.png"
  },
  {
    "type": "Hijacking",
    "phrase": "Say hello to my little friend!",
    "image": "images/hijacking.gif"
  },
  {
    "type": "Travelling",
    "phrase": "Are we nearly there yet?",
    "image": "images/travelling_3.gif"
  },
  {
    "type": "Buying",
    "phrase": "Splashing the cash.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingSeeds",
    "phrase": "Get me some seeds man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingGas",
    "phrase": "Lovely Gas!",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingChemicals",
    "phrase": "Get me some chemicals man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingAmmo",
    "phrase": "Locked and loaded.",
    "image": "https://78.media.tumblr.com/043296378155d0ac7fa386538d4f5786/tumblr_oreqvl5Mut1wqfvrxo1_500.gif"
  },
  {
    "type": "BuyingCars",
    "phrase": "Get me some seeds man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingGrowGear",
    "phrase": "Get me some seeds man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingWeapons",
    "phrase": "Say hello",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingProtection",
    "phrase": "Get me some seeds man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "BuyingCapacity",
    "phrase": "Get me some seeds man.",
    "image": "https://i.giphy.com/media/Ts4dedatClXB6/giphy.webp"
  },
  {
    "type": "Selling",
    "phrase": "Making a deal.",
    "image": "https://monese.files.wordpress.com/2015/03/moneygif.gif"
  },
  {
    "type": "SellingWeed",
    "phrase": "Ka Ching!",
    "image": "http://78.media.tumblr.com/tumblr_mdn0fynFx01rg930uo1_500.gif"
  },
  {
    "type": "SellingCoke",
    "phrase": "Money is powder.",
    "image": "https://media3.giphy.com/media/SnwifA7bOFDhe/giphy.gif"
  }
];

export const getActionAnimation = function (type){

  for (var i=0;i<actionAnimations.length;i++){

    if (actionAnimations[i].type==type){
      return  actionAnimations[i];
    }
  }


}
