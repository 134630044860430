import React, { Component } from "react";

import {TravelButton, BusyButton , EndCooldownButton} from "./ActionButtons";
import {ActionAnimation} from "./ActionAnimation"

import {getBackgroundImage,getCharacterImage,districtNames , percentageWidthStyle , CURRENCY_SYMBOL} from "../GlobalConfig"

import {narco} from '../contracts/NarcoStateWatch'
import {getMarketItems, districtsState , travelCost , saleDangerZonePercent} from "../contracts/DistrictsWatch"

import TypeWriter from 'react-typewriter';

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

import { FadeInUpDiv , FadeInDiv } from '../UITransitions';


// finds the exit for target district
// returns -1 if not exists
function findExitByDistrict(currentDistrict, targetDistrict){
  if (currentDistrict==targetDistrict)return -1;
  var exits = districtsState[currentDistrict-1].exits;
  return exits.findIndex(x => x==targetDistrict);
}

class DistrictInfoCard extends Component {


  render() {

    const marketItems = getMarketItems(this.props.district);

    return (

      <div id="district-info-card">



      <table class="table lab travelTable mx-auto w-75">





      <thead class="text-center">


      <tr>
      <td colspan="4" ><div class="markettitle mt-2">{strings.districtNames[this.props.district-1]} </div></td>
      </tr>
      <tr>
      <td colspan="4"><p class="population">{strings.population}: {districtsState[this.props.district-1].population}</p></td>
      </tr>

      </thead>

        <tbody>
          <tr>
            <td colspan="2" class="p-0 title text-center"><p class="pt-2 pb-0 mb-0">{strings.weedprices}</p></td>
            <td colspan="2" class="p-0 title text-center"><p class="border-left border-light pt-2 pb-0 mb-0">{strings.cokeprices}</p></td>
          </tr>

          <tr class="text-center mt-0">

            <td>
            <p class="badge bbadge bg-danger">{strings.sell}</p>
            <p>{marketItems[0].sellPrice.toFixed(2)} {CURRENCY_SYMBOL}</p>
            </td>

            <td class="border-right border-light">
            <p class="badge bbadge bg-success">{strings.buy}</p>
            <p>{marketItems[0].price.toFixed(2)} {CURRENCY_SYMBOL}</p>
            </td>

            <td class="border-left border-light">
            <p class="badge bbadge bg-danger">{strings.sell}</p>
            <p>{marketItems[1].sellPrice.toFixed(2)} {CURRENCY_SYMBOL}</p>
            </td>


            <td>
            <p class="badge bbadge bg-success">{strings.buy}</p>
            <p>{marketItems[1].price.toFixed(2)} {CURRENCY_SYMBOL}</p>
            </td>

          </tr>

          <tr class="text-center">
          <td colspan="2" class="border-right border-light">{strings.weedsupply}:  {districtsState[this.props.district-1].weedAmountHere} oz</td>
          <td colspan="2" class="border-left border-light">{strings.cokesupply}:  {districtsState[this.props.district-1].cokeAmountHere} oz</td>
          </tr>


      <tr class="text-center">
      <td colspan="2" class="title p-0"> <p class="pb-0 mb-0 pt-3">{strings.youhave}</p></td>
      <td colspan="2" class="title p-0"> <p class="pb-0 mb-0 border-left border-light pt-3">{strings.travelcost} </p></td>
      </tr>

      <tr class="text-center">
      <td colspan="2"><p class="mt-0 pt-0"><img src="../css/images/gas.png" height="25"/><span class={(narco.gas==0)? "text-danger" : null}>{narco.gas} {strings.gallons}</span></p></td>
      <td colspan="2" class="pb-2 border-left border-light"><p class="mt-0 pt-0">{travelCost} {CURRENCY_SYMBOL}</p></td>
      </tr>


      {(narco.travelCooldownProgress==100)?
        null
        :

        <tr>


        <td colspan="4">
        <div class="title text-center mb-2">{strings.looking} {narco.travelCooldownProgress}%</div>
          <div class="progress w-75 mx-auto">
            <div class={(narco.travelCooldownProgress<saleDangerZonePercent)?  "danger-bar" : "progress-bar"} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={percentageWidthStyle(narco.travelCooldownProgress)} ></div>
          </div>
          <p/>
          <div class="btn btn-success">
            <EndCooldownButton cooldown={0}/>
          </div>
          </td>
        </tr>

    }


      {
              (narco.travelCooldownProgress==100)?

              <tr>

              <td colspan="2" class="text-center">

              {(narco.isTravelling==true)?
                <div class="btn btn-success">
                  <BusyButton />
                  </div>
               :
                <span>
                {(narco.gas==0)?
                    <FadeInUpDiv>
                    {strings.canttravel}
                    </FadeInUpDiv>
                  :
                  <span>

                  { (findExitByDistrict(narco.currentDistrict , this.props.district)!=-1)?

                    <FadeInUpDiv>
                    <div class="btn btn-success">

                      <TravelButton text={strings.travel} district={this.props.district} exit={findExitByDistrict(narco.currentDistrict , this.props.district)}/>

                      </div>
                      </FadeInUpDiv>
                    :

                    null
                  }
                    </span>
                }

                </span>
              }


              </td>

              {
                /*  AIRLIFT FUNCTIONALITY */
                (narco.isTravelling==false && (this.props.district!=narco.currentDistrict))?

                <td colspan="2">
                <FadeInUpDiv>
                  <div class="btn btn-success">
                    <TravelButton text="AirLift" district={this.props.district} exit={this.props.district}/>
                  </div>
                  </FadeInUpDiv>
                </td>

                :
                null


              }


              </tr>

              :
              <tr>
              <div class="mx-auto m-3 nobuttonsmall text-center">&nbsp;</div>
              </tr>
      }

      </tbody>
      </table>

      </div>
    );
  }
}



class Travel extends Component {



  constructor(props) {
    super(props);
    this.state = {
      narco: narco,
      focusedDistrict: narco.currentDistrict
    }
  }

  componentDidMount(){
        this.updateState();
        this.interval  = setInterval(this.updateState.bind(this), 5000)
     }

     componentWillUnmount(){
           console.log("Clearing interval for Travel.");
           clearInterval(this.interval)
        }

   updateState(){
      this.setState({
            narco: narco
      })
   }




getInfoIconStyle(district) {
    var infoIconLocations = [{x:70,y:15},{x:67,y:49},{x:70,y:82},{x:22,y:82},{x:12,y:47},{x:24,y:17},{x:41,y:47}];
    var locationColor="white";
    if (district==narco.currentDistrict){
      locationColor="red";
    }else{
      if (findExitByDistrict(narco.currentDistrict , district)!=-1){
        locationColor="green";
      }
    }
    return {'fontSize':"4vh", color:locationColor, position: "absolute", top: infoIconLocations[district-1].y+"%" , left: infoIconLocations[district-1].x+"%",cursor: "pointer", fontFamily: "Bangers", '-webkit-text-stroke-width': "0.2vh", '-webkit-text-stroke-color': "black"}
}


  render() {
    return (
      <div class="row h-100">


        {(narco.isTravelling==false)?

          <div class="col-7 my-auto"><img src="../../images/doperaidermap.png" width="100%;"/>

          {
            ('en' ==  getLanguage())?
            <div>
            <div style={this.getInfoIconStyle(1)} onClick={() => {this.setState({focusedDistrict: 1 }) }}><p class="mapTitle">{strings.districtone}</p></div>
            <div style={this.getInfoIconStyle(2)} onClick={() => {this.setState({focusedDistrict: 2 }) }}><p class="mapTitle">{strings.districttwo}</p></div>
            <div style={this.getInfoIconStyle(3)} onClick={() => {this.setState({focusedDistrict: 3 }) }}><p class="mapTitle">{strings.districtthree}</p></div>
            <div style={this.getInfoIconStyle(4)} onClick={() => {this.setState({focusedDistrict: 4 }) }}><p class="mapTitle">{strings.districtfour}</p></div>
            <div style={this.getInfoIconStyle(5)} onClick={() => {this.setState({focusedDistrict: 5 }) }}><p class="mapTitle">{strings.districtfive}</p></div>
            <div style={this.getInfoIconStyle(6)} onClick={() => {this.setState({focusedDistrict: 6 }) }}><p class="mapTitle">{strings.districtsix}</p></div>
            <div style={this.getInfoIconStyle(7)} onClick={() => {this.setState({focusedDistrict: 7 }) }}><p class="mapTitle">{strings.districtseven}</p></div>
            </div>
            :

            <div>
            <div style={this.getInfoIconStyle(1)} onClick={() => {this.setState({focusedDistrict: 1 }) }}><p class="mapTitlech">{strings.districtone}</p></div>
            <div style={this.getInfoIconStyle(2)} onClick={() => {this.setState({focusedDistrict: 2 }) }}><p class="mapTitlech">{strings.districttwo}</p></div>
            <div style={this.getInfoIconStyle(3)} onClick={() => {this.setState({focusedDistrict: 3 }) }}><p class="mapTitlech">{strings.districtthree}</p></div>
            <div style={this.getInfoIconStyle(4)} onClick={() => {this.setState({focusedDistrict: 4 }) }}><p class="mapTitlech">{strings.districtfour}</p></div>
            <div style={this.getInfoIconStyle(5)} onClick={() => {this.setState({focusedDistrict: 5 }) }}><p class="mapTitlech">{strings.districtfive}</p></div>
            <div style={this.getInfoIconStyle(6)} onClick={() => {this.setState({focusedDistrict: 6 }) }}><p class="mapTitlech">{strings.districtsix}</p></div>
            <div style={this.getInfoIconStyle(7)} onClick={() => {this.setState({focusedDistrict: 7 }) }}><p class="mapTitlech">{strings.districtseven}</p></div>
            </div>
          }

          </div>

          :

          <div class="col-7 my-auto">

          <div class="narcocardSpace markettitle">In Transit<TypeWriter typing={1}>....</TypeWriter><p/>
          <img src="images/ripple.gif"/>
          </div>

          </div>

        }



          <div class="col-5 my-auto">
          <DistrictInfoCard district={this.state.focusedDistrict} travel={(findExitByDistrict(narco.currentDistrict , this.state.focusedDistrict)!=-1)}/>
          </div>
        </div>
    );
  }
}

export default Travel;
