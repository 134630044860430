import React, { Component } from "react";
import {narco} from '../contracts/NarcoStateWatch'

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

class NarcoInventory extends Component {


  render() {

    return (

      <div class="row h-100 events col-12">
          <div class="col-10 lab my-auto mx-auto h-75 scrollable">

          <div class="markettitle m-3 mb-4">{strings.inventory}</div>

          <div class="row">


          <div class="col-5 mx-auto">

              <div class="row  mt-2">

              <div class="col-12 title mb-2">{strings.attack}</div>

              <div class="col-4">
              {
                (narco.purchasedItems[15]==true) ?
                <img src="../item-icons/15.png" class="inventoryone" />
                :
                <img src="../item-icons/15b.png" class="inventoryone" />
              }
              <div class="inventoryText mx-auto">{strings.attack} <br /> +10</div>
              </div>

              <div class="col-4">
              {
                (narco.purchasedItems[16]==true) ?
                <img src="../item-icons/16.png" class="inventorytwo" />
                :
                <img src="../item-icons/16b.png" class="inventorytwo" />
              }
              <div class="inventoryText mx-auto">{strings.attack}<br /> +20</div>
              </div>
              <div class="col-4">
              {
                (narco.purchasedItems[17]==true) ?
                <img src="../item-icons/17.png" class="inventorythree" />
                :
                <img src="../item-icons/17b.png" class="inventorythree" />
              }
              <div class="inventoryText mx-auto">{strings.attack}<br /> +40</div>
              </div>

              </div>









          <div class="row mt-2">

          <div class="col-12 title mb-2">{strings.defense}</div>

          <div class="col-4">
          {
            (narco.purchasedItems[18]==true) ?
            <img src="../item-icons/18.png" class="inventoryone" />
            :
            <img src="../item-icons/18b.png" class="inventoryone" />
          }
          <div class="inventoryText mx-auto">{strings.defense}<br /> +10</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[19]==true) ?
            <img src="../item-icons/19.png" class="inventorytwo" />
            :
            <img src="../item-icons/19b.png" class="inventorytwo" />
          }
          <div class="inventoryText mx-auto">{strings.defense}<br /> +20</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[20]==true) ?
            <img src="../item-icons/20.png" class="inventorythree" />
            :
            <img src="../item-icons/20b.png" class="inventorythree" />
          }
          <div class="inventoryText mx-auto">{strings.defense}<br /> +40</div>
          </div>
          </div>








          <div class="row mt-2">

          <div class="col-12 title mb-2">{strings.carry}</div>

          <div class="col-4">
          {
            (narco.purchasedItems[21]==true) ?
            <img src="../item-icons/21.png" class="inventoryone" />
            :
            <img src="../item-icons/21b.png" class="inventoryone" />
          }
          <div class="inventoryText mx-auto">{strings.carry}<br /> +20</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[22]==true) ?
            <img src="../item-icons/22.png" class="inventorytwo" />
            :
            <img src="../item-icons/22b.png" class="inventorytwo" />
          }
          <div class="inventoryText mx-auto">{strings.carry}<br /> +40</div>
          </div>
          <div class="col-4">
          {
            (narco.purchasedItems[23]==true) ?
            <img src="../item-icons/23.png" class="inventorythree" />
            :
            <img src="../item-icons/23b.png" class="inventorythree" />
          }
          <div class="inventoryText mx-auto">{strings.carry}<br /> +80</div>
          </div>
          </div>

    </div>


    <div class="col-5 mx-auto">





    <div class="row  mt-2">

    <div class="col-12 title mb-2">{strings.speed}</div>

          <div class="col-4">
          {
            (narco.purchasedItems[6]==true) ?
            <img src="../item-icons/6.png" class="inventoryone" />
            :
            <img src="../item-icons/6b.png" class="inventoryone" />
          }
          <div class="inventoryText mx-auto">{strings.speed}<br /> +10</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[7]==true) ?
            <img src="../item-icons/7.png" class="inventorytwo" />
            :
            <img src="../item-icons/7b.png" class="inventorytwo" />
          }
          <div class="inventoryText mx-auto">{strings.speed}<br /> +20</div>
          </div>
          <div class="col-4">
          {
            (narco.purchasedItems[8]==true) ?
            <img src="../item-icons/8.png" class="inventorythree" />
            :
            <img src="../item-icons/8b.png" class="inventorythree" />
          }
          <div class="inventoryText mx-auto">{strings.speed}<br /> +40</div>
          </div>
          </div>








          <div class="row mt-2">

          <div class="col-12 title mb-2">{strings.grow}</div>

          <div class="col-4">
          {
            (narco.purchasedItems[9]==true) ?
            <img src="../item-icons/9.png" class="inventoryone" />
            :
            <img src="../item-icons/9b.png" class="inventoryone" />
          }
          <div class="inventoryText mx-auto">{strings.grow}<br /> +10</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[10]==true) ?
            <img src="../item-icons/10.png" class="inventorytwo" />
            :
            <img src="../item-icons/10b.png" class="inventorytwo" />
          }
          <div class="inventoryText mx-auto">{strings.grow}<br /> +20</div>
          </div>
          <div class="col-4">
          {
            (narco.purchasedItems[11]==true) ?
            <img src="../item-icons/11.png" class="inventorythree" />
            :
            <img src="../item-icons/11b.png" class="inventorythree" />
          }
          <div class="inventoryText mx-auto">{strings.grow}<br /> +40</div>
          </div>
          </div>








          <div class="row mt-2">

          <div class="col-12 title mb-2">{strings.produce}</div>

          <div class="col-4">
          {
            (narco.purchasedItems[12]==true) ?
            <img src="../item-icons/12.png" class="inventoryone" />
            :
            <img src="../item-icons/12b.png" class="inventoryone" />
          }
          <div class="inventoryText mx-auto">{strings.produce}<br /> +10</div>
          </div>

          <div class="col-4">
          {
            (narco.purchasedItems[13]==true) ?
            <img src="../item-icons/13.png" class="inventorytwo" />
            :
            <img src="../item-icons/13b.png" class="inventorytwo" />
          }
          <div class="inventoryText mx-auto">{strings.produce}<br /> +20</div>
          </div>
          <div class="col-4">
          {
            (narco.purchasedItems[14]==true) ?
            <img src="../item-icons/14.png" class="inventorythree" />
            :
            <img src="../item-icons/14b.png" class="inventorythree" />
          }
          <div class="inventoryText mx-auto">{strings.produce}<br /> +40</div>
          </div>
          </div>

          </div>
          </div>
          </div>
          </div>

    );
  }
}

export default NarcoInventory;
