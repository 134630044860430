import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import Main from './main';

import NarcoPanel from './sides/NarcoPanel';
import {narco} from "./contracts/NarcoStateWatch"

import NarcoStateWatch from "./contracts/NarcoStateWatch"

import {getBackgroundImage,getCharacterImage} from "./GlobalConfig";

import {DistrictsWatch} from "./contracts/DistrictsWatch"
import EventsWatch from "./contracts/EventsWatch"


import Market from "./districts/Market"
import Laboratory from "./districts/Laboratory"
import Hijacking from "./districts/Hijacking"
import Travel from "./districts/Travel"
import Help from "./districts/Help"
import Events from "./districts/Events"


import {HashRouter,withRouter} from "react-router-dom";

class Game extends Component {

  constructor(props) {
    super(props);
    this.state = {
      narco: narco,
      initialized: false
    }
  }

  componentWillMount(){
        this.updateState();
        setInterval(this.updateState.bind(this), 1000)
     }
   updateState(){
      this.setState({
            narco: narco
      })
   }

  render() {


    /** take some time to get initialized */
    if (narco.genes=="") return (
      <div class="row">
      <div class="col-lg-5">&nbsp;</div>
      <div class="col-lg-2 my-auto"><p><img src="images/general-loader.gif"/></p></div>
      <div class="col-lg-5">Initiallizing...</div>
      <NarcoStateWatch/>
      <DistrictsWatch/>
      </div>
    )

    return (
      <HashRouter>
      <span>
      <NarcoStateWatch/>
      <DistrictsWatch/>
      <EventsWatch/>


      <div class="row district-background" style={getBackgroundImage(narco.currentDistrict)}>

      <div class="col-md-3 my-auto narcopanel">
        <div class="mx-auto">
        <NarcoPanel />
        </div>
      </div>
      <div class="col-lg-9 col-sm-11 mx-auto">

        <div class="district-character">
        <Main style={getCharacterImage(narco.currentDistrict)}/>
        </div>
        <nav class="navbar fixed-top">
        <Header />
        </nav>
        <nav class="navbar fixed-bottom">
        <Footer />
        </nav>

        </div>
      </div>
      </span>
      </HashRouter>
    );
  }
}

export default Game;
