import React, { Component } from 'react';
import {Route,NavLink,HashRouter,withRouter} from "react-router-dom";

import {narcoCoreContractInstance , getWeb3} from "./contracts/Contracts"

import {RecruitButton , ChangeIdentityButton} from "./districts/ActionButtons";
import {arrayContains, removeArrayItem} from './util/ArrayUtil'


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import "./css/custom-slick2.css";

import NarcoCard from "./narco/NarcoCard"

import NarcoInstance from "./narco/NarcoInstance"

import {FadeInDiv } from './UITransitions';
import {EXCLUDED_NARCOS} from "./GlobalConfig"

import GetSomeNarcos from "./GetSomeNarcos"
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);

class SelectNarco extends Component {

  constructor(props) {
    super(props);
    this.state = {
       initialized: false,
       activeSlide: 0,
       ownsNone: true,
       myNarcos: []
    }
  }

  componentWillMount(){
        this.updateState();
        //setInterval(this.updateState.bind(this), 3000)
     }
   updateState = async()=>{

    // var web3 = getWeb3();
     let accts=  [];
     if (Web3.givenProvider !== null) {

     //console.log(web3I);
      await web3I.eth.requestAccounts();
      accts = await web3I.eth.getAccounts();
    }

    if (accts.length>0){

      const myNarcos = await  narcoCoreContractInstance().narcosByOwner(accts[0]).call();

      if (myNarcos.length===0){
        this.setState({initialized: true});
        return;
      }

      let theseOwnedNarcos = [];
      for (var j=0;j<myNarcos.length;j++){

        narcoCoreContractInstance().getNarco(parseInt(myNarcos[j]))

        .call({},(err, result) => {
         console.log(result)
          if(result != null){
            var skills = result[3];
  
                 var narco = {
                    'id' : parseInt(result[9]),
                    'name': result[0],
                    'genes': result[5],
                    'respect': parseInt(result[7]),
                    'speed': parseInt(skills[0]),
                    'grow': parseInt(skills[1]),
                    'refine': parseInt(skills[2]),
                    'attack': parseInt(skills[3]),
                    'defense': parseInt(skills[4]),
                    'carry': parseInt(skills[5]),
                    'home' :parseInt(result[6]),
                    'weedTotal' : parseInt(result[1]),
                    'cokeTotal' : parseInt(result[2]),
                    'homeLocation' : parseInt(result[6]),
                    'level': parseInt(result[7])
                 }
  
                 theseOwnedNarcos.push(narco);
                 this.setState({myNarcos: theseOwnedNarcos})
                 console.log("theseOwnedNarcos.length " + theseOwnedNarcos.length)
                 console.log("myNarcos.length " + myNarcos.length)
           
                 if (theseOwnedNarcos.length===myNarcos.length){
                   this.setState({initialized: true, myNarcos: theseOwnedNarcos})
                 }
              }

        })


       
      }// end for narcos
      


    } // end if accounts
   
  
    
   }


    selectNarco(narcoId){
      NarcoInstance.currentNarcoId=narcoId;
      console.log("Setting current narco = " + NarcoInstance.currentNarcoId);
    }

  render() {

    if (this.state.initialized == true && this.state.myNarcos.length==0) return (
    <GetSomeNarcos/>
    )

    /** take some time to get initialized */
    if (this.state.initialized == false) return (
      <div class="row h-100">
      <div class="col-lg-5">&nbsp;</div>
      <div class="col-lg-2 my-auto"><img src="images/general-loader.gif"/>Fetching your narcos...</div>
      <div class="col-lg-5">&nbsp;</div>
      </div>
    )


    var settings = {
      dots: false,
      arrows: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      afterChange: current => this.setState({ activeSlide: current })
    };


    const myNarcoCards = this.state.myNarcos.map((thisNarco, index) => <NarcoCard narco={thisNarco} isTarget="true" />);


    return (


      <div class="col-sm-12 col-md-8 row mx-auto my-auto h-100 text-center d-flex pt-5">

      <div class="col-3 mx-auto my-auto p-3">
      <div class="mainheading">Recruit a narco</div>
      <div class="text-center text-dark">Respect Needed: 150</div>

      {
        (this.state.myNarcos[this.state.activeSlide].level >= 150) ?
        <div class="btn btn-success  mx-auto mt-5"><RecruitButton respect={this.state.myNarcos[this.state.activeSlide].level}  narcoId={this.state.myNarcos[this.state.activeSlide].id} narcoName={this.state.myNarcos[this.state.activeSlide].name}/></div>
        :
        <div class="btn btn-warning  mx-auto mt-5">Recruit</div>
      }


      </div>


        <div class="col-6 mx-auto my-auto">

            <Slider {...settings}>
            {myNarcoCards}
            </Slider>

          <div class="btn btn-success w-25 m-0 mx-auto mb-5" onClick={() => { this.selectNarco(this.state.myNarcos[this.state.activeSlide].id)}}><i class="fas fa-play"></i></div>


        </div>

        <div class="col-3 mx-auto my-auto p-3">

         <div class="mainheading">Change Identity</div>
         <div class="text-center text-dark">Cost: 150 POA</div>
         {
           (this.state.myNarcos[this.state.activeSlide].level>=0) ?
           <div class="btn btn-success  mx-auto mt-5"><ChangeIdentityButton respect={this.state.myNarcos[this.state.activeSlide].level} fromGenes={this.state.myNarcos[this.state.activeSlide].genes} narcoId={this.state.myNarcos[this.state.activeSlide].id} narcoName={this.state.myNarcos[this.state.activeSlide].name}/></div>
           :
           <div class="btn btn-warning  mx-auto mt-5">Change</div>
         }

        </div>


      </div>


    );
  }
}

export default SelectNarco;
