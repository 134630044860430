import React, { Component } from "react";

import {getActionAnimation} from "./actionAnimations"

export class ActionAnimation extends Component {
 constructor(props) {
    super(props);
    console.log("Creating " +this.props.type+" action animation.");
    this.state = {
      animation: getActionAnimation(this.props.type)
    }
  }

  render() {
    return (
      <div style={{'text-align': 'right'} , {width: "100%"}}>
         <img height="200" src={this.state.animation.image} style={{display: 'block'} } align="center"/>
      </div>
    );
  }
}
