import React, { Component } from "react";

import {getBackgroundImage,getHomeFlagImage} from "./GlobalConfig"

import {narcoCoreContractInstance, getWeb3} from "./contracts/Contracts"

import {strings , setLanguage, getLanguage} from "./LocaleStrings"

export class Leaderboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalSupply: 0,
      leaders: []
    }

  }

componentWillMount(){
    this.updateState();
    this.interval = setInterval(this.updateState.bind(this), 5000);
}

updateState = async()=>{
  fetch(
    "https://doperaider-state.s3.eu-west-1.amazonaws.com/leaderboard.json"
  )
    .then((response) => response.json())
    .then( (leaderboard) => {
      this.setState({
        leaders: leaderboard
      })
  });
 }


componentWillUnmount(){
       //console.log("Clearing interval for Leaderboard.");
       //clearInterval(this.interval)
}



  render() {


    var rank = 0;
    const leaders = this.state.leaders.map((narco, index) =>{
      rank++;
      return(<Leader key={index} narco={narco} rank={rank}/>);
    }
    );



    return (

      <div class="row h-100  col-12">
          <div class="col-10 lab my-auto mx-auto h-75 scrollable">



          <div>
          <div class="markettitle text-center m-3">{strings.leaderboard}</div>



          <table class="table w-75 mx-auto table-sm">


          <tr>
          <td><p class={"title"}>{strings.rank}</p></td>
          <td><p class={"title"}>{strings.name}</p></td>
          <td><p class={"title"}>{strings.home}</p></td>
          <td><p class={"title"}>{strings.respect}</p></td>
          <td><p class={"title"}>{strings.activity}</p></td>
          </tr>

          <tbody>


        {leaders}

          </tbody>

          </table>

          </div>

          </div>

     </div>

    );
  }
}

class Leader extends React.Component {
  render() {


    return (
          <tr>
          <td><p class={"title"}>{this.props.rank}</p></td>
          <td><p>{this.props.narco.name}</p></td>
          <td><img src={"flag-icons/"+this.props.narco.home+".svg"} width="30" height="30"/></td>
          <td><p>{this.props.narco.respect}</p></td>
          <td><p>{this.props.narco.activity}</p></td>
          </tr>
    )
  }
}


export default Leaderboard;
