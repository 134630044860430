import React, { Component } from "react";

import Slider from "react-slick";

class Help extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

      <div class="row h-100 events col-12">
      <div class="col-10 lab my-auto mx-auto h-75">

      <div>

      <Slider {...settings}>



      <div>

      <div class="d-flex justify-content-center col-8 mx-auto m-5">
      <div class="col-4"><img src="../css/images/foursmall.png" class="ftwenty" /></div>
      <div class="col-8 text-left speech-bubble">Hi! My name is Four Twenty. I will explain what is what in this short tutorial.
      All important stats of your Narco are displayed on your Narco card:</div>
      </div>

      <div><img src="../css/images/tut1.png" class="img-fluid mx-auto" /></div>
      </div>







      <div>

      <div class="d-flex justify-content-center col-8 mx-auto m-5">
      <div class="col-4"><img src="../css/images/foursmall.png" class="ftwenty" /></div>
      <div class="col-8 text-left speech-bubble">Consumables are required for various activities in the DopeRaider world. You should
      always make sure that you have enough consumables for the actions you have planned
      or you might get caught short!</div>
      </div>

      <div><img src="../css/images/tut2.png" class="img-fluid mx-auto" /></div>
      </div>





      <div>

      <div class="d-flex justify-content-center col-8 mx-auto m-5">
      <div class="col-4"><img src="../css/images/foursmall.png" class="ftwenty" /></div>
      <div class="col-8 text-left speech-bubble">This is the market in Baltimore. Each district will have it's own prices based on global
      and local supply of Dope, so you will always have stay sharp and look around for the
      best price!
      Each district has it's own speciality offerings and heritage, but remember that
      everything will always be available in Vice Island... if you're willing to pay the price.</div>
      </div>

      <div><img src="../css/images/tut3.png" class="img-fluid mx-auto" /></div>
      </div>



      <div>

      <div class="d-flex justify-content-center col-8 mx-auto m-5">
      <div class="col-4"><img src="../css/images/foursmall.png" class="ftwenty" /></div>
      <div class="col-8 text-left speech-bubble">This is the production screen, it is only available whilst in your home district where your
      safe house is.
      Purchase better production equipment to increase batch size as well as reduce
      cooldown between batches to get your operations running real smooth! 
      </div>
      </div>

      <div><img src="../css/images/tut4.png" class="img-fluid mx-auto" /></div>
      </div>




      </Slider>
      </div>


  </div>
  </div>
    );
  }
}

export default Help;