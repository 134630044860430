import React, { Component } from 'react';
import {Route,NavLink,HashRouter,withRouter} from "react-router-dom";

import Market from "./districts/Market"
import Laboratory from "./districts/Laboratory"
import Hijacking from "./districts/Hijacking"
import Travel from "./districts/Travel"
import Help from "./districts/Help"
import DopeRaiderTokens from "./districts/DopeRaiderTokens"

import Events from "./districts/Events"
import NarcoInventory from "./narco/NarcoInventory"

import Leaderboard from "./Leaderboard"
import { FadeInSlowDiv } from './UITransitions';

import SelectNarco from "./SelectNarco"

import GoogleAnalytics from "./GoogleAnalytics"

class Main extends Component {
  render() {
    return (
      <HashRouter>
          <FadeInSlowDiv>
      <div class="main">
          <Route exact path="/" component={Market}/>
          <Route path="/NarcoInventory" component={NarcoInventory}/>
          <Route path="/Market" component={Market}/>
          <Route path="/Laboratory" component={Laboratory}/>
          <Route path="/Hijacking" component={Hijacking}/>
          <Route path="/Travel" component={Travel}/>
          <Route path="/Help" component={Help}/>
          <Route path="/Events" component={Events}/>
          <Route path="/Leaderboard" component={Leaderboard}/>
          <Route path="/DopeRaiderTokens" component={DopeRaiderTokens}/>

          <GoogleAnalytics />
      </div>
          </FadeInSlowDiv>
      </HashRouter>
    );
  }
}

export default Main;
