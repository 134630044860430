import React, { Component } from 'react';
import {Route,NavLink,HashRouter} from "react-router-dom";

import {narco} from './contracts/NarcoStateWatch'

import {strings , setLanguage, getLanguage} from "./LocaleStrings"

import {ShowNarcoPanelButton} from "./districts/ActionButtons"

import LocalizedComponent from './LocalizedComponent'

import SkyLight from 'react-skylight';
import NarcoPanelWide from './sides/NarcoPanelWide';



  var mobileScreenDialogStyle = {
    backgroundColor: 'transparent',
    color: '#ffffff',
    textAlign: 'center',
    width: '75%',
    minHeight: '75vh',
    left: '40%',
    marginBottom: '-50%',
  };

class Footer extends Component {


  render() {
    return (
       <HashRouter>
        <div class="footer w-100">
        {
          (narco.isTravelling==false)?
          <div class="row d-flex justify-content-center mb-3">

            <div class="button narcobutton">

            <SkyLight
                dialogStyles={mobileScreenDialogStyle}
                hideOnOverlayClicked
                ref={ref => this.narcoPanelWideDialog = ref}
              >
            <span>

            <div class="mx-auto">
            <NarcoPanelWide />
            </div>


            </span>
            </SkyLight>

            <a onClick={() => {
              //narco.isRecruiting=true;

              this.narcoPanelWideDialog.show();

            }}>
            {strings.narco}
            </a>


            </div>
            <div class="button"><NavLink to="/Market">{strings.m}</NavLink></div>
            <div class="button"><NavLink to="/Travel">{strings.d}</NavLink></div>
            <div class="button"><NavLink to="/Laboratory">{strings.p}</NavLink></div>
            <div class="button"><NavLink to="/Hijacking">{strings.r}</NavLink></div>
            <div class="button"><NavLink to="/NarcoInventory">{strings.i}</NavLink></div>
            <div class="button"><NavLink to="/Events">{strings.e}</NavLink></div>
            <div class="button"><NavLink to="/Leaderboard">{strings.l}</NavLink></div>

          </div>
          :
          null
        }

        </div>
      </HashRouter>
    );
  }
}

export default Footer;
