import React, { Component } from "react";

import {getBackgroundImage,getCharacterImage,getDistrictGreeting, districtStoreNames, CURRENCY_SYMBOL} from "../GlobalConfig"
import {BuyButton , SellButton} from "./ActionButtons";
import {ActionAnimation} from "./ActionAnimation"
import TypeWriter from 'react-typewriter';
import {Route,NavLink,HashRouter} from "react-router-dom";

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../css/custom-slick2.css"

import {getMarketItems,saleDangerZonePercent} from "../contracts/DistrictsWatch"
import {narco} from '../contracts/NarcoStateWatch'

function getTopRightStyle() {
      return {position: "absolute", top: "1%" , left: "93%"}
  }


class Market extends Component {


  constructor(props) {
    super(props);
    this.state = {
      narco: narco
    }
  }

     componentDidMount(){
           this.updateState();
           this.interval = setInterval(this.updateState.bind(this), 3000)
        }

      componentWillUnmount(){
            console.log("Clearing interval for Market.");
            clearInterval(this.interval)
         }


   updateState(){
      this.setState({
            narco: narco
      })
   }


  render() {
    var settings = {
      dots: false,
      arrows: true,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
    };


   // console.log(narco.currentDistrict);
    
    const marketItems = getMarketItems(narco.currentDistrict).map((item, index) =>
    <MarketItem key={index} value={item} />
  );

//  console.log(marketItems);

    return (

      <div class="row h-100">

      <div class="col-lg-7 col-sm-8 my-auto">


      <div class="welcome text-center lab">
      <div class="welcometext mx-auto text-left col-10 p-2">

      <span><h3 class="markettitle">{strings.welcome[narco.currentDistrict-1]}</h3><p>{strings.welcomeText[narco.currentDistrict-1]}</p> </span>

        {/*getDistrictGreeting(getLanguage(),narco.currentDistrict)*/}



          {(narco.currentDistrict == 7)?
            <div class="text-center">
              <p>Here you can redeem your DopeRaider Tokens:</p>
                <div class="btn btn-success nostyle">
                <NavLink to="/DopeRaiderTokens">DopeRaider Tokens</NavLink>
              </div>
              </div>
            :
            null
          }



      </div>
      </div>

      <Slider {...settings}>
      {marketItems}
      </Slider>

      </div>
      <div class="col-lg-5 col-sm-4 district-character" style={getCharacterImage(narco.currentDistrict)}></div>
      </div>
    );
  }
}

class MarketItem extends React.Component {

  constructor(props) {
      super(props);



      var sellQty = 1;
      var buyQuantity = 1;
      var minBuyQuantity = 1;

      if (this.props.value.id==0){
        sellQty=narco.weedTotal;
        buyQuantity=12;
        minBuyQuantity=12;
      }



      if (this.props.value.id==1){
        sellQty=narco.cokeTotal;
        buyQuantity=4;
        minBuyQuantity=4;
      }

      if (narco.remainingCapacity==0 && narco.gas==0 && narco.currentDistrict==narco.homeLocation){
        sellQty=1;
      }


      this.state = {
        buyQuantity: buyQuantity,
        minBuyQuantity: minBuyQuantity,
        buyPrice: this.props.value.price*buyQuantity,
        overbid: 0,
        sellQuantity: sellQty,
        view: "main"
      }

    }



    componentWillMount(){
          this.updateState();
          setInterval(this.updateState.bind(this), 1000)
       }

     updateState(){

     }

    incrementBuyQty(){
        if (this.state.buyQuantity<narco.remainingCapacity){
          var newBuyPrice = this.props.value.price*(this.state.buyQuantity+1) +this.state.overbid;
          this.setState({ buyQuantity: this.state.buyQuantity+1 , buyPrice: newBuyPrice});
        }
    }

    incrementOverbid(){
          var newOverbid = this.state.overbid+1;
          var newBuyPrice = this.props.value.price*(this.state.buyQuantity) +newOverbid;
          this.setState({ buyPrice: newBuyPrice , overbid: newOverbid});
    }

   decrementBuyQty(){
      if (this.state.buyQuantity>this.state.minBuyQuantity){
              var newBuyPrice = this.props.value.price*(this.state.buyQuantity-1)+this.state.overbid;
              this.setState({ buyQuantity: this.state.buyQuantity-1 , buyPrice: newBuyPrice})
      }
    }

    resetBuy(){
          var newOverbid = 0;
            var newBuyPrice = this.props.value.price*(this.state.buyQuantity);
          this.setState({ buyPrice: newBuyPrice , overbid: 0 });
    }


    incrementSellQty(){

          if (narco.remainingCapacity==0 && narco.gas==0 && narco.currentDistrict==narco.homeLocation){
            return;
          }

          if (this.props.value.id==0 && narco.weedTotal>this.state.sellQuantity){
              this.setState({ sellQuantity: this.state.sellQuantity+1})
          }
          if (this.props.value.id==1 && narco.cokeTotal>this.state.sellQuantity){
              this.setState({ sellQuantity: this.state.sellQuantity+1})
          }
    }

   decrementSellQty(){
      if (this.state.sellQuantity>1){
              this.setState({ sellQuantity: this.state.sellQuantity-1})
      }
    }


    getBuyCarryItemButton(){
      if (this.props.value.id<6 && narco.remainingCapacity<this.state.minBuyQuantity){
        return(<div class="btn btn-warning"><i class="fas fa-ban"></i> {strings.buy}</div>);
      }
      return(  <button type="button" class="btn btn-success" onClick={() => { this.setState({view: "buy"})}}>{strings.buy}</button>);
    }

    renderMain() {
        const rows = [];
        rows.push(<tr><td colspan="3" class="title">&nbsp;</td></tr>);

        rows.push(<tr><td colspan="3" class="title">{strings.ppo}</td></tr>);
        rows.push(<tr><td>{this.props.value.sellPrice.toFixed(2)} {CURRENCY_SYMBOL}</td><td>{this.props.value.price.toFixed(2)} {CURRENCY_SYMBOL}</td></tr>);


        if ((this.props.value.id==0 && narco.weedTotal>0 || this.props.value.id==1 && narco.cokeTotal>0) && narco.currentDistrict!=narco.homeLocation){
          rows.push(
            <tr><td><div class="btn btn-danger" onClick={() => { this.setState({view: "sell"})}}>{strings.sell}</div></td><td>
          {this.getBuyCarryItemButton()}
          </td></tr>);
        }else{

          rows.push(<tr><td><div class="btn btn-warning"><i class="fas fa-ban"></i> {strings.sell}</div></td><td>{this.getBuyCarryItemButton()}</td></tr>);

        }



        rows.push(<tr><td colspan="3" class="title">&nbsp;</td></tr>);

        return rows;
    }

    renderBuyDope(){
      const rows = [];

     rows.push(<tr>
              <td class="text-right"><button type="button" class="btn bg-dark" onClick={() => { this.decrementBuyQty()}}>-</button></td>
              <td class="numbertitle align-middle text-center">{this.state.buyQuantity}</td>
              <td class="text-left"><button type="button" class="btn bg-dark" onClick={() => { this.incrementBuyQty()}}>+</button></td>
              </tr>);

     rows.push(<tr><td colspan="3">{strings.price}</td></tr>);

     rows.push(<tr>
              <td class="text-right"><button type="button" class="btn bg-dark" onClick={() => { this.resetBuy()}}><i class="fas fa-undo"></i>

</button></td>
              <td class="numbertitle align-middle text-center">{this.state.buyPrice.toFixed(2)} {CURRENCY_SYMBOL}</td>
              <td class="text-left"><button type="button" class="btn bg-dark" onClick={() => { this.incrementOverbid()}}>+</button></td>
              </tr>);


     rows.push(<tr><td class="text-right"><button type="button" class="btn btn-dark m-2"  onClick={() => { this.setState({view: "main"})}}>BACK</button></td>
              <td></td>
              <td class="text-left"><BuyButton parent={this} item={this.props.value.id} quantity={this.state.buyQuantity} price={this.state.buyPrice} bid={true}/></td>
              </tr>);

      return rows;
    }


    renderSellDope(){
      const rows = [];

     rows.push(<tr>
              <td class="text-right"><button type="button" class="btn bg-dark" onClick={() => { this.decrementSellQty()}}>-</button></td>
              <td class="numbertitle align-middle text-center">{this.state.sellQuantity}</td>
              <td class="text-left"><button type="button" class="btn bg-dark" onClick={() => { this.incrementSellQty()}}>+</button></td>
              </tr>);

     rows.push(<tr><td colspan="3">{strings.price}</td></tr>);

     rows.push(<tr>
              <td class="text-right">&nbsp;</td>
              <td class="numbertitle align-middle text-center">{(this.props.value.sellPrice*this.state.sellQuantity).toFixed(2)} {CURRENCY_SYMBOL}</td>
              <td class="text-left">&nbsp;</td>
              </tr>);


     rows.push(<tr><td class="text-right"><button type="button" class="btn btn-dark m-2"  onClick={() => { this.setState({view: "main"})}}>{strings.back}</button></td>
              <td></td>
              <td class="text-left"><SellButton parent={this} item={this.props.value.id} quantity={this.state.sellQuantity}/></td>
              </tr>);

      return rows;
    }


    renderBuyConsumable(){
      const rows = [];

     rows.push(<tr>
              <td class="text-right"><button type="button" class="btn bg-dark" onClick={() => { this.decrementBuyQty()}}>-</button></td>
              <td class="numbertitle align-middle text-center">{this.state.buyQuantity}</td>
              <td class="text-left"><button type="button" class="btn bg-dark" onClick={() => { this.incrementBuyQty()}}>+</button></td>
              </tr>);

     rows.push(<tr><td colspan="3">{strings.price}</td></tr>);

     rows.push(<tr>
              <td>&nbsp;</td>
              <td class="numbertitle align-middle text-center">{this.state.buyPrice.toFixed(2)} {CURRENCY_SYMBOL}</td>
              <td>&nbsp;</td>
              </tr>);


              if (narco.remainingCapacity==0){
                rows.push(<tr>
                         <td>&nbsp;</td>
                         <td class="text-center"><div class="btn btn-warning"><i class="fas fa-ban"></i> {strings.buy}</div></td>
                         <td></td>
                         </tr>);
              }
              else{
                rows.push(<tr>
                         <td>&nbsp;</td>
                         <td class="text-center"><BuyButton item={this.props.value.id} quantity={this.state.buyQuantity} price={this.props.value.price}/></td>
                         <td></td>
                         </tr>);
              }


      return rows;
    }

    renderBuyUpgrade(){
      const rows = [];

     rows.push(<tr>
              <td class="text-right">&nbsp;</td>
              <td class="title align-middle text-center">&nbsp;</td>
              <td class="text-left">&nbsp;</td>
              </tr>);

     rows.push(<tr><td colspan="3">{strings.price}</td></tr>);

     rows.push(<tr>
              <td>&nbsp;</td>
              <td class="numbertitle align-middle text-center">{this.state.buyPrice.toFixed(2)} {CURRENCY_SYMBOL}</td>
              <td>&nbsp;</td>
              </tr>);


        if (this.props.value.levelRequired>narco.level && narco.currentDistrict!=7) {

          rows.push(<tr>
                   <td>&nbsp;</td>
                   <td class="text-center"><span class="text-success title">{strings.needrespect}</span></td>
                   <td></td>
                   </tr>);

        }else{


          if (narco.purchasedItems[this.props.value.id]==false)
          {

            // check they have done correct upgrade path


          if (
            (narco.skills[this.props.value.skillNumber]<this.props.value.upgrade && narco.skills[this.props.value.skillNumber]>=this.props.value.skillRequired)
            ||
            (this.props.value.skillNumber==5 && narco.skills[this.props.value.skillNumber]<20+this.props.value.upgrade && narco.skills[this.props.value.skillNumber]>=this.props.value.skillRequired)
          )
          {
            // ok show the buy button
            rows.push(<tr>
                     <td>&nbsp;</td>
                     <td class="text-center"><BuyButton item={this.props.value.id} quantity={1} price={this.props.value.price}/></td>
                     <td></td>
                     </tr>);
          }else{
            // previous upgrade not purchased yet

            rows.push(<tr>
                     <td>&nbsp;</td>
                     <td class="text-center"><span class="text-success numbertitle">need {this.props.value.skillAffected} {this.props.value.skillRequired} +</span></td>
                     <td>&nbsp;</td>
                     </tr>);

          }



          }else{
                // already purchased
              rows.push(<tr>
                       <td>&nbsp;</td>
                       <td class="text-center"><span class="text-success title">{strings.purchased}</span></td>
                       <td>&nbsp;</td>
                       </tr>);

            }

          }
      return rows;
    }



  render() {
    return (


      <div class="m-3 p-3">

      <table class="table table-sm lab marketTable">

      <thead>
      <tr>
      <td colspan="3"><div class="markettitle text-center">{strings.items[this.props.value.id]}</div></td>
      </tr>
      </thead>

      <tbody>

      <tr>
      <td colspan="3">
      <div class="slideritem">
      <img class="mx-auto" src={"item-icons/"+this.props.value.id+".png"} width="40%"/>
      </div>
      </td>
      </tr>

      <tr>
      <td colspan="3">
      <div class="text-center">
      {
        (this.props.value.levelRequired && narco.currentDistrict!=7) ?
          "Respect needed: " + this.props.value.levelRequired
          :
        <span>&nbsp;</span>
      }
      &nbsp;&nbsp;
      {
         (this.props.value.upgrade) ?
           this.props.value.skillAffected + " +" + this.props.value.upgrade
           :
         <span>&nbsp;</span>
       }

      </div>
      </td>
      </tr>




      {
        (this.props.value.id<2 && this.state.view=="main")?
          this.renderMain()
          :
         null
      }

      {
        (this.props.value.id<2 && this.state.view=="buy")?
          this.renderBuyDope()
          :
         null
      }

      {
        (this.props.value.id<2 && this.state.view=="sell")?
          this.renderSellDope()
          :
         null
      }


      {
        (this.props.value.id>=2 && this.props.value.id<6 )?
         this.renderBuyConsumable()
          :
         null
      }

      {
        (this.props.value.id>5 )?
          this.renderBuyUpgrade()
          :
         null
      }


      </tbody>

      </table>

      </div>


    )
  }
}


export default Market;
