import React, { Component } from "react";

import NarcoCard from "../narco/NarcoCard"

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

import {narco , focusedNarco} from '../contracts/NarcoStateWatch'
import {getBackgroundImage,getCharacterImage , getDistrictMapImage , percentageWidthStyle, getNarcoCardImage , CURRENCY_SYMBOL} from "../GlobalConfig"
import {HijackButton , BusyButton, EndCooldownButton} from "./ActionButtons";
import {ActionAnimation} from "./ActionAnimation"

import {districtsState , hijackCost , saleDangerZonePercent} from "../contracts/DistrictsWatch"
import { BounceInDiv , FadeInDiv } from '../UITransitions';
import { narcoCoreContractInstance, districtsCoreContractInstance, getWeb3, districtsContractABI, narcoContractABI, poalNarcoContractAddress, poaDistrictsContractAddress } from "../contracts/Contracts"

import TypeWriter from 'react-typewriter';
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);
const districtsContract = new web3I.eth.Contract(districtsContractABI, poaDistrictsContractAddress);
const narcoCoreContract = new web3I.eth.Contract(narcoContractABI, poalNarcoContractAddress);

function calcCooldownProgress(cooldownTime, speedSkill){
//narcoCore.setCooldown( _narcoId ,  0 , now + (455-(5*narcoSkills[0])* 1 seconds));

        cooldownTime = cooldownTime+5; // sometimes it's awry the timing

         var currentSecond = Date.now()/1000;
         var maxCooldownPeriod = 455-(5*speedSkill);
         var cooldownRemaining  = Math.max(cooldownTime-currentSecond,0);
         var coolDownProgress = ((maxCooldownPeriod-cooldownRemaining)/maxCooldownPeriod)*100;
         return parseInt(Math.max(0,coolDownProgress));
}

  

class Hijacking extends Component {

  constructor(props) {
    super(props);
    var randomTops = [78];
    var randomLefts = [50];

    for (var t=0;t<1000;t++){
      randomTops.push(Math.floor(Math.random() * 60) + 20);
      randomLefts.push(Math.floor(Math.random() * 75)+5)
    }

    this.state = {
      nearbyNarcoIds: "",
      nearbyNarcos: [],
      randomTops: randomTops,
      randomLefts: randomLefts,
      focusedNarcoId: -1
    }

  }


  componentWillMount(){
    this.updateState();
    //this.interval = setInterval(this.updateState.bind(this), 2000)
 }

  componentDidMount(){
       
       // this.interval = setInterval(this.updateState.bind(this), 2000)
  }

     componentWillUnmount(){
            console.log("Clearing interval for Raiding.");
            clearInterval(this.interval)
     }


  identifyNarco = async(narcoId)=>{

    console.log("Identifying... " + narcoId);
    this.setState({targetting: true , focusedNarcoId: narcoId}) 


    console.log("Calling... " + narcoId);

    narcoCoreContractInstance().getNarco(narcoId)
    .call({},(err, result) => {
      console.log(result);
      if (result!==undefined){

        var skills = result[3];




        result.name=result[0];
        result.homeLocation=parseInt(result[6]);
        result.level=parseInt(result[7]);
        result.weedTotal=parseInt(result[1]);
        result.cokeTotal=parseInt(result[2]);
        result.speed=parseInt(skills[0]);
        result.grow=parseInt(skills[1]);
        result.refine=parseInt(skills[2]);
        result.attack=parseInt(skills[3]);
        result.defense=parseInt(skills[4]);
        result.carry=parseInt(skills[5]);

        this.setState({targetting: false, focusedNarco: result});
      }
    });
   

   
  }
    

   updateState = async()=>{
    

    fetch(
      "https://doperaider-state.s3.eu-west-1.amazonaws.com/"+narco.currentDistrict+"-district-narcos.json"
    )
      .then((response) => response.json())
      .then(async (localNarcos) => {
        console.log(localNarcos)
        let nearbyNarcoIdsJson = JSON.stringify(localNarcos);
        let theseNarcos = []

        for (let n=0;n<Math.min(localNarcos.length,10);n++){
        
          var narc = {};
          const narcoUrl = "https://doperaider-state.s3.eu-west-1.amazonaws.com/"+localNarcos[n]+".json"
          console.log(narcoUrl);
          let narcoJson = fetch(narcoUrl)
          .then((response) => response.json())
          .then(async (narcoResult) => {

            console.log(narcoResult);
            //narc.id=parseInt(narcoResult.id);
            //narc.genes=narcoResult[5];
            theseNarcos.push(narcoResult);
  
            console.log(n);
            if (n==9){
              console.log("done with narcos");
              this.setState({
                nearbyNarcos: theseNarcos, nearbyNarcoIds: nearbyNarcoIdsJson
              })
            }
 
           

          })
         

        
        
        }

       

    });

   
   }

   

  getRandomNarcoPosition(narco) {
      return {position: "absolute", top: this.state.randomTops[narco]+"%" , left: this.state.randomLefts[narco]+"%", cursor: "pointer"}
  }

  render() {

    return (
      <div class="row h-100 my-auto">

            <div class="col-7 col-sm-8 my-auto">
            <div>
            <table class="table lab mx-auto">
            <tr>
            <td><div class="markettitle">Raiding: {this.state.nearbyNarcos.length}</div></td>
            <td><img src="../css/images/ammo.png" height="25px"/> <span class={(narco.ammo==0)? "text-danger" : null}> {narco.ammo} {strings.ammo}</span></td>
            <td><p>{strings.cost}: {hijackCost} {CURRENCY_SYMBOL}</p></td>
            </tr>
            </table>
            </div>
            <img src={getDistrictMapImage(narco.currentDistrict)} width="100%;"/>
            <div class="my-auto">
            {
                          this.state.nearbyNarcos.map((narc,index) => {


                           // console.log("Rendering:" + narc.id);

                                return <div onClick={() =>
                                  {
                                    console.log("click")
                                    this.identifyNarco(narc.id);

                                  }}
                                  style={this.getRandomNarcoPosition(narc.id)}>
                                  <img src={"https://narcos.doperaider.com/narcos/"+narc.genes+".png"} height="108"/>
                                  </div>;

                          })
            }
            </div>
            </div>

          <div class="col-5 col-sm-4 my-auto">

            { (this.state.focusedNarco!=-1)?

              <div class="mx-auto">

                 {(this.state.targetting)
                 &&
                 <div class="narcocardSpace markettitle">{strings.identifying}<TypeWriter typing={1}>....</TypeWriter><p/>
                 <img src="images/ripple.gif"/>
                 </div>
                 }

                  {
                    (this.state.focusedNarcoId!=-1 && !this.state.targetting) &&
                    <BounceInDiv>
                    <NarcoCard narco={this.state.focusedNarco} isTarget={"true"}/>
                    </BounceInDiv>
                  }


                {(this.state.focusedNarcoId!=-1 && !this.state.targetting) &&
                
           
                <table class="table table-sm raid lab consumables mx-auto">



                {(narco.isHijacking==true)?
                      <td colspan="2" class="btn w-50 mx-auto"><BusyButton /></td>
                 :
                 <tr>
                      {
                        (narco.ammo>0 && narco.attackCooldownProgress==100 ) ?
                        <td colspan="2">
                        <div class="btn btn-success w-50 mx-auto"><HijackButton targetNarcoId={this.state.focusedNarcoId} /></div>
                        </td>
                        :
                        <td colspan="2">
                        <div class="nobuttonsmall w-75 mx-auto title">
                        {/*(focusedNarco.homeLocation==narco.currentDistrict && focusedNarco.id!=0) ? "Can't raid Narcos in their home." : null*/}
                        {(narco.ammo==0) ? "You need bullets!" : null}
                        </div>
                        </td>
                      }
                </tr>
                }

                {(narco.attackCooldownProgress==100)?
                  null
                  :

                  <tr>
                  <td colspan="2">
                  <div class="title text-center mb-2">{strings.cleaning}: {narco.attackCooldownProgress}%</div>

                  <div class="progress mx-auto">
                  <div class={(narco.attackCooldownProgress<saleDangerZonePercent)?  "danger-bar" : "progress-bar"} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={percentageWidthStyle(narco.attackCooldownProgress)} ></div>
                  </div>

                  <p/>
                  <div class="btn btn-success">
                    <EndCooldownButton cooldown={3}/>
                  </div>
                    </td>
                  </tr>

              }

                </table>
   
  }
              </div>

              :null

            }


            </div>
     </div>
    );
  }
}

export default Hijacking;
