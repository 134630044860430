import React, { Component } from "react";
import SkyLight from 'react-skylight';

import {narcoCoreContractInstance,districtsCoreContractInstance, getWeb3 , districtsContractABI , poaDistrictsContractAddress} from "./Contracts"
import {narco , NarcoStateWatch} from '../contracts/NarcoStateWatch'
import {DistrictsWatch} from '../contracts/DistrictsWatch'

import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';



import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import {districtNames} from "../GlobalConfig"

import Web3 from 'web3';
const web3 = new Web3(Web3.givenProvider);
const districtsContract = new web3.eth.Contract(districtsContractABI,poaDistrictsContractAddress);


function sleep(milliseconds) {
 var start = new Date().getTime();
 for (var i = 0; i < 1e7; i++) {
   if ((new Date().getTime() - start) > milliseconds){
     break;
   }
 }
}


async function identifyNarco(id) {
  const narcoUrl = "https://doperaider-state.s3.eu-west-1.amazonaws.com/"+id+".json"
  const response = await fetch(narcoUrl);
  const narco = await response.json();  
  console.log(narco);
  return narco;
}



class EventsWatch extends Component {

    static instance = null;
    static initialized = false;
    static lastFiredEventTxHash = "";
    static history = null;

    static events = [
      {style: "bg-info" , category: strings.eventCategories[2], content: 'You just arrived here (ha!)', when:  new Date()},
      {style: "bg-success" , category: strings.eventCategories[0], content: 'You re-entered DopeRaider world!',when:  new Date()}
    ]

    constructor(props) {
      super(props);
      
    }

    static showModal = (eventContent)=>{

      EventsWatch.instance.stateDialogText = (
        <div class="title mx-auto my-auto">
            {eventContent}
            <p/>
            <p/>
            <div class="btn btn-success mx-auto mt-5 title" onClick={() =>
              {
                EventsWatch.instance.stateDialog.hide();}
              }>OK!</div>
        </div>
      );
      EventsWatch.instance.stateDialog.show();
    
    }

    componentDidMount(){
          console.log(this.props);
          EventsWatch.instance = this;
          EventsWatch.history = this.props.history;

          if (!EventsWatch.initialized){

            console.log("initializing events....");


              districtsContract.events.allEvents({
                fromBlock: "latest"
              })
              .on('data', (event) => {
                console.log(event);

          //  event Hijacked(uint256 indexed hijacker, uint256 indexed victim , uint16 stolenWeed , uint16 stolenCoke);
          //  event HijackDefended(uint256 indexed hijacker, uint256 indexed victim);
          //  event EscapedHijack(uint256 indexed hijacker, uint256 indexed victim , uint8 escapeLocation);

                let eventContent = "";
                if (event.event==="HijackDefended"){
                  eventContent="HijackDefended"

                  var victimId = event.returnValues.victim;
                  var hijackerId = event.returnValues.hijacker;

                  identifyNarco(victimId).then(victim=>{
                    let victimName = victim[0];
                    identifyNarco(hijackerId).then(hijacker=>{
                     let hijackerName = hijacker[0];

                     var eventContent = victimName + " " +strings.successfullyDefended1+  " " + hijackerName + " " + strings.successfullyDefended2;
                     eventContent = eventContent + "" + strings.maybeNextTime;

                     var d = new Date();
                     var newEvent = {
                       style: "bg-info",
                       category: strings.eventCategories[4],
                       content: eventContent,
                       when: d
                     }
 
                     EventsWatch.events.unshift(newEvent);
                     if (EventsWatch.events.length>100){
                       EventsWatch.events.pop();
                     }

                    }) // end identify victim
                  }) // end identify hijacker


                  if (victimId==narco.id || hijackerId==narco.id){
                    EventsWatch.showModal(eventContent)
                  }

                }  // end HijackDefended

                if (event.event==="Hijacked"){
                  eventContent="Hijacked"

                  var victimId = event.returnValues.victim;
                  var hijackerId = event.returnValues.hijacker;
                  var stolenWeed = event.returnValues.stolenWeed;
                  var stolenCoke = event.returnValues.stolenCoke;


                  identifyNarco(victimId).then(victim=>{
                   let victimName = victim[0];

                   identifyNarco(hijackerId).then(hijacker=>{
                    let hijackerName = hijacker[0];
 
                    eventContent = victimName + strings.raidedBy+ hijackerName +" ";
                    if (stolenWeed>0) eventContent = eventContent + stolenWeed+ " " + strings.weed+ " ";
                    if (stolenCoke>0) eventContent = eventContent + stolenCoke+ " " + strings.coke
                    if (stolenWeed>0 || stolenCoke>0){
                      eventContent = eventContent + strings.stolenOuch;
                    }else{
                       eventContent = eventContent + strings.nothingStolen;
                    }

                    var d = new Date();
                    var newEvent = {
                      style: "bg-danger",
                      category: strings.eventCategories[1],
                      content: eventContent,
                      when: d
                    }

                    EventsWatch.events.unshift(newEvent);
                    if (EventsWatch.events.length>100){
                      EventsWatch.events.pop();
                    }
 
                    if (victimId==narco.id || hijackerId==narco.id){
                      EventsWatch.showModal(eventContent)
                    }
                  }) // end identify
                }) // end identify

                }  // end hijacked.


                if (event.event==="EscapedHijack"){
                  eventContent="EscapedHijack"


                  var victim = event.returnValues.victim;
                  var hijacker = event.returnValues.hijacker;

                  identifyNarco(victimId).then(victim=>{
                    let victimName = victim[0];
                    identifyNarco(hijackerId).then(hijacker=>{
                     let hijackerName = hijacker[0];

                     var eventContent = victimName + strings.raidedBy + hijackerName + ". ";
                     eventContent = eventContent + strings.luckyEscape;

                     toast.info(eventContent, {
                         position: toast.POSITION.TOP_RIGHT
                     });

                     var d = new Date();
                     var newEvent = {
                       style: "bg-warning",
                       category: strings.eventCategories[4],
                       content: eventContent,
                       when: d
                     }

                     EventsWatch.events.unshift(newEvent);
                     if (EventsWatch.events.length>100){
                       EventsWatch.events.pop();
                     }

                      if (victimId==narco.id || hijackerId==narco.id){
                        EventsWatch.showModal(eventContent)
                      }
                    }) // end identify
                  }) // end identify

 
                }  // end EscapedHijack

                if (event.event==="TravelBust"){
                  eventContent="TravelBust"

                  var bustedNarcoId = event.returnValues.narcoId;
                  var confiscatedWeed = event.returnValues.confiscatedWeed
                  var confiscatedCoke = event.returnValues.confiscatedCoke

                  identifyNarco(bustedNarcoId).then(narco=>{
                    narco.name=narco[0];
                    
                    var eventContent = narco.name + " "+strings.bustedByOfficerLardass+" " + confiscatedWeed + "  " +strings.weedTaken+ " "  + confiscatedCoke + " " + strings.cokeConfiscated;

                    if (confiscatedWeed==0 && confiscatedCoke==0){
                      eventContent = eventContent + " "+strings.luckyEscape;
                    }else{
                        eventContent = eventContent + " YAK!!";
                    }

                    toast.warn(eventContent, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    console.log(eventContent);

                    var d = new Date();
                    var newEvent = {
                      style: "bg-danger",
                      category: strings.eventCategories[3],
                      content: eventContent,
                      when: d
                    }

                    EventsWatch.events.unshift(newEvent);
                    if (EventsWatch.events.length>100){
                      EventsWatch.events.pop();
                    }

                    if (arrivedDistrictNumber==narco.currentDistrict && travellingNarco!=narco.id){
                      toast.info(eventContent, {
                          position: toast.POSITION.TOP_RIGHT
                      });
                    }


                    if (bustedNarcoId==narco.id){
                      EventsWatch.showModal(eventContent)
                    }
                  }) // end identify narco


                } // end travel bust

                if (event.event==="NarcoArrived"){
                  eventContent="NarcoArrived"

                  var arrivedLocation = strings.districtNames[event.returnValues.location-1];
                  var arrivedDistrictNumber = event.returnValues.location;
                  var travellingNarco = event.returnValues.narcoId;

                  identifyNarco(travellingNarco).then(narco=>{
                    narco.name=narco[0];
                    var eventContent = narco.name + strings.XXXjustArrivedYYY + arrivedLocation;
                    console.log(eventContent);

                    var d = new Date();
                    var newEvent = {
                      style: "bg-success",
                      category: strings.eventCategories[2],
                      content: eventContent,
                      when: d
                    }

                    EventsWatch.events.unshift(newEvent);
                    if (EventsWatch.events.length>100){
                      EventsWatch.events.pop();
                    }

                    if (arrivedDistrictNumber==narco.currentDistrict && travellingNarco!=narco.id){
                      toast.info(eventContent, {
                          position: toast.POSITION.TOP_RIGHT
                      });
                    }

                  }) // end identify narco

                }
                // end of events watch
              })
              .on('error', console.error);
          
                        
          
            EventsWatch.initialized = true;
          }



    }

  render() {


    var eventStateDialogStyle = {
      backgroundColor: '#606060',
      color: '#ffffff',
      textAlign: 'center',
      width: '50%',
      height: '10%',
      minHeight: '250px',
      padding: '35px',
      borderRadius: '5px',
      boxShadow: '0 0 0 2px #000, 0 0 0 10px rgba(96, 96, 96, 0.9), 0 0 0 12px #000'
  };


    return (
      <span>

      <SkyLight
          dialogStyles={eventStateDialogStyle}
          hideOnOverlayClicked
          ref={ref => this.stateDialog = ref}
        >
        {this.stateDialogText}
      </SkyLight>

      </span>

    );
  }
}

export default withRouter(EventsWatch);
