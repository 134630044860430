import React, { Component } from 'react';
import {NavLink,HashRouter} from "react-router-dom";

import {FadeInDiv } from './UITransitions';


class GetSomeNarcos extends Component {
  render() {
    return (
       <div>
            <div class="row">
              &nbsp;
            </div>
        <div class="container col-sm-8 landing text-center">

        <FadeInDiv>
          <section>
            <div class="row">

              <div class="col-sm-6 align-self-center">
                <h1 class="mainheading m-0">get buying at the market!</h1>
                <p>Listen to El Cabo - he knows what he&apos; talking about!</p>

                <div class="button mt-5"><a href="https://doperaider.com/#/NarcoMarket">Narco Market</a></div>
                </div>

            <div class="col-sm-6 align-self-center"><img class="cabo" src="../css/eclabo-600.png" alt="El Cabo" title="El Cabo"></img></div>

            </div>
          </section>
          </FadeInDiv>


        </div>

      </div>
    );
  }
}
export default GetSomeNarcos;
