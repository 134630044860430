import React, { Component } from 'react';
import ApplicationRoutes from "./ApplicationRoutes"

class App extends Component {
  render() {
    return (
      <span>
        <ApplicationRoutes/>
      </span>
    );
  }
}

export default App;
