import React, { Component } from "react";
import TimeAgo from 'react-timeago'


import {narco} from '../contracts/NarcoStateWatch'
import {getBackgroundImage,getCharacterImage} from "../GlobalConfig"

import EventsWatch from "../contracts/EventsWatch"
import {strings , setLanguage, getLanguage} from "../LocaleStrings"



export class Events extends Component {


  constructor(props) {
    super(props);
    this.state = {
      events: EventsWatch.events
    }

  }

componentWillMount(){
      this.updateState()
      this.interval = setInterval(this.updateState.bind(this), 7e3)
   }

   componentWillUnmount(){
          console.log("Clearing interval for Events.");
          clearInterval(this.interval)
   }

 updateState(){

    console.log("Updating Events");

    var currentEvents = EventsWatch.events;
    console.log("Number of events: " + EventsWatch.events.length);

    if (currentEvents.length>50){
     currentEvents =  currentEvents.slice(currentEvents.length-50,50);
     EventsWatch.events = currentEvents;
    }


    this.setState({
          events: currentEvents
    })

 }



  render() {


    const events = this.state.events.map((event, index) =>
      <Event key={index} value={event} />
    );


    return (
      <div class="row h-100 col-12">




          <div class="col-10 lab my-auto mx-auto eventsscroll h-75">

          <div class="row">



          <div class="col-12">
          <p class="markettitle m-3">Events</p>

          <table class="table w-75 mx-auto table-sm">

          <tbody>


          {events}


          </tbody>

          </table>

          </div>

          </div>


          </div>

     </div>
    );
  }
}

class Event extends React.Component {
  render() {
    return (
          <tr>
          <td><p class={"badge " + this.props.value.style}>{this.props.value.category}</p></td>
          <td class="text-left"><p>{this.props.value.content}</p></td>
          <td><p><TimeAgo date={this.props.value.when} /></p></td>
          </tr>
    )
  }
}


export default Events;
