import React, { Component } from "react";

import {tokenContractInstance , tokenContractInstanceI, getWeb3} from "../contracts/Contracts"
import {narco} from '../contracts/NarcoStateWatch'
import NarcoStateWatch from "../contracts/NarcoStateWatch"

import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
const expectedBlockTime = 5000;


var defaultGas=3; // gwei
var web3 = getWeb3();
/*
web3.eth.getGasPrice(function(priceErr, getGasPrice) {
// console.log(getGasPrice);
  defaultGas = getGasPrice/1000000000;
})
*/

export class DopeRaiderTokens extends Component {

  constructor(props) {
    super(props);
    this.state = {
      seedTokens: 0,
      chemicalsTokens: 0,
      gasTokens: 0,
      ammoTokens: 0,
      weedTokens: 0,
      cokeTokens: 0,
      redeemingSeeds: 1,
      redeemingChemicals: 1,
      redeemingAmmo: 1,
      redeemingGas: 1,
      redeemingWeed: 1,
      redeemingCoke: 1
    }
  }

  componentDidMount(){
    this.updateState();
    this.interval = setInterval(this.updateState.bind(this), 3000)
 }

componentWillUnmount(){
     console.log("Clearing interval for tokens.");
     clearInterval(this.interval)
  }


  redeemableQuantity(tokenCount){
    if (tokenCount==0)return 0;
    if (tokenCount<=narco.remainingCapacity){
      return tokenCount;
    }
    return narco.remainingCapacity;
  }

 redeemToken = async(tokenType , quantity)=>{

    var web3 = getWeb3();

    const accts = await web3I.eth.getAccounts();

    tokenContractInstanceI(web3I , tokenType).methods.redeemToken(narco.id , quantity).send(
      { from: accts[0], gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei') , gas: 500000 },

    async (err, transactonHash) => {


      if (transactonHash != null && transactonHash != undefined) {
       

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        if (transactionReceipt.status=="0x1") {

          NarcoStateWatch.instance.narcoStateDialogText = (
            <div class="markettitle mx-auto my-auto">

            Your DopeRaider tokens have been redeemed.

            <p/>
            <p/>

            <div class="btn btn-success mx-auto mt-5 title" onClick={() =>
             {
               NarcoStateWatch.instance.stateDialog.hide();}
             }>OK!</div>

            </div>
          );
          NarcoStateWatch.instance.stateDialog.show();
        }


      }

    }); // end send

 }

 updateState = async ()=>{

    var web3 = getWeb3();
    const accts = await web3I.eth.getAccounts();

   tokenContractInstance("SEEDS").methods.balanceOf(accts[0]).call({},(err, balance) => {
               if(balance != null){
                  this.setState({
                        seedTokens: parseInt(balance)
                  })
               }
     })

     tokenContractInstance("CHEMICALS").methods.balanceOf(accts[0]).call({},(err, balance) => {
                 if(balance != null){
                    this.setState({
                          chemicalsTokens: parseInt(balance)
                    })
                 }
       })

       tokenContractInstance("GAS").methods.balanceOf(accts[0]).call({},(err, balance) => {
                   if(balance != null){
                      this.setState({
                            gasTokens: parseInt(balance)
                      })
                   }
         })

         tokenContractInstance("AMMO").methods.balanceOf(accts[0]).call({},(err, balance) => {
                     if(balance != null){
                        this.setState({
                              ammoTokens: parseInt(balance)
                        })
                     }
           })

           tokenContractInstance("WEED").methods.balanceOf(accts[0]).call({},(err, balance) => {
                       if(balance != null){
                          this.setState({
                                weedTokens: parseInt(balance)
                          })
                       }
             })

             tokenContractInstance("COKE").methods.balanceOf(accts[0]).call({},(err, balance) => {
                         if(balance != null){
                            this.setState({
                                  cokeTokens: parseInt(balance)
                            })
                         }
               })

 }


  render() {

    return (
      <div class="row h-100 events col-12">
          <div class="col-10 lab my-auto mx-auto h-75 scrollable">
          <div>
          <div class="markettitle m-3 mb-3">Tokens</div>




          <table class="table w-75 mx-auto mt-3 table-sm">



          <tbody>

          <tr>
          <td class="title">Seeds</td>
          <td class="title">CHEMICALS</td>
          <td class="title">AMMO</td>
          </tr>

          <tr>
          <td><img src="../css/images/seedtoken.png" width="75px" class="m-3 tokens"/></td>
          <td><img src="../css/images/chemtoken.png" width="75px" class="m-3 tokens"/></td>
          <td><img src="../css/images/ammotoken.png" width="75px" class="m-3 tokens"/></td>
          </tr>

          <tr>
          <td>{this.state.seedTokens} tokens</td>
          <td>{this.state.chemicalsTokens} tokens</td>
          <td>{this.state.ammoTokens} tokens</td>
          </tr>

          <tr>
          <td>
          {
            (this.redeemableQuantity(this.state.seedTokens)>0)?
            <span>
            <input type="range" min="1" id="seedRedeemAmount" max={this.redeemableQuantity(this.state.seedTokens) } class="slider"  value={this.state.redeemingSeeds}  onChange={(e) => {this.setState({redeemingSeeds: document.getElementById("seedRedeemAmount").value})}} />
            <br/>{this.state.redeemingSeeds}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("SEEDS" , this.state.redeemingSeeds) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          <td>
          {
            (this.redeemableQuantity(this.state.chemicalsTokens)>0)?
            <span>
            <input type="range" min="1" id="chemicalRedeemAmount" max={this.redeemableQuantity(this.state.chemicalsTokens) } class="slider"  value={this.state.redeemingChemicals}  onChange={(e) => {this.setState({redeemingChemicals: document.getElementById("chemicalRedeemAmount").value})}} />
            <br/>{this.state.redeemingChemicals}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("CHEMICALS" , this.state.redeemingChemicals) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          <td>
          {
            (this.redeemableQuantity(this.state.ammoTokens)>0)?
            <span>
            <input type="range" min="1" id="ammoRedeemAmount" max={this.redeemableQuantity(this.state.ammoTokens) } class="slider"  value={this.state.redeemingAmmo}  onChange={(e) => {this.setState({redeemingAmmo: document.getElementById("ammoRedeemAmount").value})}} />
            <br/>{this.state.redeemingAmmo}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("AMMO" , this.state.redeemingAmmo) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          </tr>

          <tr>
          <td class="title">GAS</td>
          <td class="title">WEED</td>
          <td class="title">COKE</td>
          </tr>

          <tr>
          <td><img src="../css/images/gastoken.png" width="75px" class="m-3 tokens"/></td>
          <td><img src="../css/images/weedtoken.png" width="75px" class="m-3 tokens"/></td>
          <td><img src="../css/images/coketoken.png" width="75px" class="m-3 tokens"/></td>
          </tr>

          <tr>
          <td>{this.state.gasTokens} tokens</td>
          <td>{this.state.weedTokens} tokens</td>
          <td>{this.state.cokeTokens} tokens</td>
          </tr>

          <tr>
          <td>
          {
            (this.redeemableQuantity(this.state.gasTokens)>0)?
            <span>
            <input type="range" min="1" id="gasRedeemAmount" max={this.redeemableQuantity(this.state.gasTokens) } class="slider"  value={this.state.redeemingGas}  onChange={(e) => {this.setState({redeemingGas: document.getElementById("gasRedeemAmount").value})}} />
            <br/>{this.state.redeemingGas}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("GAS" , this.state.redeemingGas) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          <td>
          {
            (this.redeemableQuantity(this.state.weedTokens)>0)?
            <span>
            <input type="range" min="1" id="weedRedeemAmount" max={this.redeemableQuantity(this.state.weedTokens) } class="slider"  value={this.state.redeemingWeed}  onChange={(e) => {this.setState({redeemingWeed: document.getElementById("weedRedeemAmount").value})}} />
            <br/>{this.state.redeemingWeed}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("WEED" , this.state.redeemingWeed) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          <td>
          {
            (this.redeemableQuantity(this.state.cokeTokens)>0)?
            <span>
            <input type="range" min="1" id="cokeRedeemAmount" max={this.redeemableQuantity(this.state.cokeTokens) } class="slider"  value={this.state.redeemingCoke}  onChange={(e) => {this.setState({redeemingCoke: document.getElementById("cokeRedeemAmount").value})}} />
            <br/>{this.state.redeemingCoke}<p/>
            <div class="btn btn-success mb-3" onClick={() => {this.redeemToken("COKE" , this.state.redeemingCoke) }} >REDEEM</div><br/>
            </span>
            :
            <div class="btn btn-warning mb-5">REDEEM</div>
          }
          </td>
          </tr>



          </tbody>

          </table>

          </div>



          </div>


     </div>
    );
  }
}


export default DopeRaiderTokens;
