import React, { Component } from "react";

import NarcoCard from "../narco/NarcoCard"
import {narco} from '../contracts/NarcoStateWatch'

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

class NarcoPanel extends Component {

    constructor(props) {
      super(props);
      this.state = {
        narco: narco
      }
    }

    componentWillMount(){
          this.updateState();
          setInterval(this.updateState.bind(this), 1000)
       }
     updateState(){
        this.setState({
              narco: narco
        })
     }


  render() {
    return (



      <div>

      <NarcoCard narco={narco}/>

        <div class="consumables">

          <div class="title text-center">{strings.consumables}</div>

          <table class="consum mx-auto col-10">

          <tr>
            <td>
              <img src="../css/images/chemicals.png" height="25px"/>
            </td>
            <td class="chemicalsAmount">{this.state.narco.chemicals} {strings.grams}</td>

            <td>
              <img src="../css/images/seeds.png" height="25px"/>
            </td>
            <td class="chemicalsAmount">{this.state.narco.seeds} {strings.seeds}</td>
          </tr>

          <tr>
          <td>
          <img src="../css/images/gas.png" height="25px"/>
        </td>
        <td class="chemicalsAmount">{this.state.narco.gas} {strings.gallons}</td>

        <td>
          <img src="../css/images/ammo.png" height="25px"/>
        </td>
        <td class="chemicalsAmount">{this.state.narco.ammo} {strings.ammo}</td>
          </tr>

          </table>

        </div>

        <div class="consumables">
          <div class="title text-center mb-2">{strings.badges}</div>
          <div class="mx-auto col-12 text-center row d-flex justify-content-between">

          <div><img src="../css/images/badgeDeal.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Dealer badge"/><p>{this.state.narco.dealsCompleted}</p></div>

          <div><img src="../css/images/badgeGrow.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Growers badge"/><p>{this.state.narco.grows}</p></div>

          <div><img src="../css/images/badgeProduce.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Producers badge"/><p>{this.state.narco.refines}</p></div>

          <div><img src="../css/images/badgeHijack.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Hijackers badge"/><p>{this.state.narco.hijacks}</p></div>

          <div><img src="../css/images/badgeDefense.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Defense badge"/><p>{this.state.narco.defendedSuccessfully}</p></div>

          <div><img src="../css/images/badgeRaid.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Raid badge"/><p>{this.state.narco.raidsCompleted}</p></div>

          <div><img src="../css/images/badgeEscape.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Escape artist"/><p>{this.state.narco.escapes}</p></div>

          <div><img src="../css/images/badgeTravel.png" height="30rem" data-toggle="tooltip" data-placement="top" title="Traveller"/><p>{this.state.narco.traveller}</p></div>


          </div>
        </div>
      </div>
    );
  }
}

export default NarcoPanel;
