import React, { Component } from "react";
import SkyLight from 'react-skylight';
import { withRouter } from "react-router-dom";

import { narcoCoreContractInstance, districtsCoreContractInstance, getWeb3, districtsContractABI, narcoContractABI, poalNarcoContractAddress, poaDistrictsContractAddress } from "../contracts/Contracts"
import { narco, growCost, refineCost } from '../contracts/NarcoStateWatch'
import NarcoStateWatch from '../contracts/NarcoStateWatch'
import NarcoInstance from '../narco/NarcoInstance'

import { airLiftCost, travelCost, hijackCost,  endCooldownCost,  } from "../contracts/DistrictsWatch"

import { CURRENCY_SYMBOL } from "../GlobalConfig"

import NarcoPanelWide from '../sides/NarcoPanelWide';
import { strings, setLanguage, getLanguage } from "../LocaleStrings"
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);
const districtsContract = new web3I.eth.Contract(districtsContractABI, poaDistrictsContractAddress);
const narcoCoreContract = new web3I.eth.Contract(narcoContractABI, poalNarcoContractAddress);

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
const expectedBlockTime = 5000;

var defaultGas = 1; // gwei
var web3 = getWeb3();

let travelButton = ({ history, text, district, exit }) => (
  <div onClick={async () => {

    console.log("Travel to " + district + " , narco Id = " + narco.id + " exit: " + exit);  
    if (NarcoInstance.history==null)
    NarcoInstance.history = history;
      
    var travelPrice = travelCost;
    if (text == "AirLift") {
      travelPrice = airLiftCost;
    }
    if (district == 0) { return; }
    //alert("Travel to " + district + " , narco Id = " + narco.id + " exit: " + exit + " " + travelPrice);

    var web3 = getWeb3();
    const accts = await web3I.eth.getAccounts();

    await districtsContract.methods.travelTo(narco.id, exit).send({
      from: accts[0],
      value: web3.utils.toWei(travelPrice.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000
    }, async (err, transactonHash) => {

      console.log(transactonHash);
      if (transactonHash != null && transactonHash != undefined) {
        narco.destinationDistrict = district;
        narco.isTravelling = true;
        narco.focusedNarco = -1

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }
       

      }

    }); // end send






  }}>
    {(text == "AirLift") ?
      <span><i class="fas fa-plane"></i> {airLiftCost} {CURRENCY_SYMBOL}</span>
      :
      strings.travel
    }
  </div>
);
export const TravelButton = withRouter(travelButton);


let hijackButton = ({ history, text  , targetNarcoId}) => (
  <div onClick={async () => {
    if (targetNarcoId == -1) return;
    var web3 = getWeb3();
    const accts = await web3I.eth.getAccounts();

    districtsContract.methods.hijack(narco.id, targetNarcoId).send({
      from: accts[0],
      value: web3.utils.toWei(hijackCost.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000
    }, async (err, transactonHash) => {



      if (transactonHash != null && transactonHash != undefined) {
        narco.isHijacking = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        narco.isHijacking = false;

      }

    }).catch(() => { })

  }}>
    Raid
  </div>
);
export const HijackButton = hijackButton;


let buyButton = ({ history, category, item, quantity, price, bid, parent }) => (

  <button class="btn btn-success m-2" onClick={async () => {
    //  alert(quantity);
    if (quantity <= 0) { return; }

    if (item <= 1) {
      parent.setState({ view: "main" });

    }

    console.log("Buying: " + narco.id + " " + narco.currentDistrict + " " + item + " " + quantity + " " + price);
    var web3 = getWeb3();

    var buyPrice = quantity * price;

    if (bid == true) {
      buyPrice = price;
    }

    // round this up to 0.00000 places
    buyPrice = (Math.ceil((buyPrice + 0.000001) * 100000) / 100000);


    const accts = await web3I.eth.getAccounts();

    districtsContract.methods.buyItem(narco.id, narco.currentDistrict, item, quantity).send({
      from: accts[0],
      value: web3.utils.toWei(buyPrice.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000



    }, async (err, transactonHash) => {


      if (transactonHash != null && transactonHash != undefined) {
        narco.isBuying = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        if (transactionReceipt.status == "0x1") {
          NarcoStateWatch.instance.narcoStateDialogText = (
            <div class="markettitle mx-auto my-auto">

              {strings.purchaseComplete}

              <p />
              <p />

              <div class="btn btn-success mx-auto mt-5 title" onClick={() => {
                NarcoStateWatch.instance.stateDialog.hide();
              }
              }>OK!</div>


            </div>
          );
        } else {
          NarcoStateWatch.instance.narcoStateDialogText = (
            <div class="markettitle mx-auto my-auto">

              {strings.purchaseFailed}

              <p />
              <p />

              <div class="btn btn-success mx-auto mt-5 title" onClick={() => {
                NarcoStateWatch.instance.stateDialog.hide();
              }
              }>OK!</div>

            </div>
          );
        }

        NarcoStateWatch.instance.stateDialog.show();

        narco.isBuying = false;

      }

    }); // end send



  }}>
    {strings.buy} {category}
  </button>
);
export const BuyButton = buyButton;

let sellButton = ({ history, category, parent, item, quantity }) => (
  <button class="btn btn-danger m-2" onClick={async () => {

    parent.setState({ view: "main" });

    console.log("Selling: " + narco.id + " " + narco.currentDistrict + " " + item + " " + quantity);

    if (quantity <= 0) { return; }

    var web3 = getWeb3();

    const accts = await web3I.eth.getAccounts();

    districtsContract.methods.sellItem(narco.id, narco.currentDistrict, item, quantity).send({
      from: accts[0],
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000

    }, async (err, transactonHash) => {


      if (transactonHash != null && transactonHash != undefined) {
        narco.isSelling = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        if (transactionReceipt.status == "0x1") {

          NarcoStateWatch.instance.narcoStateDialogText = (

            <div class="markettitle mx-auto my-auto">
              {strings.saleComplete}


              <p />
              <p />

              <div class="btn btn-success mx-auto mt-5 title" onClick={() => {
                NarcoStateWatch.instance.stateDialog.hide();
              }
              }>OK!</div>

            </div>
          );
        }

        NarcoStateWatch.instance.stateDialog.show();


      }

      narco.isSelling = false;
    }


    );
  }}>
    {strings.sell}  {category}
  </button>
);
export const SellButton = withRouter(sellButton);

let growButton = ({ history, text }) => (
  <button class="btn btn-success mx-auto" onClick={async () => {

    var web3 = getWeb3();
    console.log("Growing: " + narco.id + " " + narco.currentDistrict + " " + growCost);


    const accts = await web3I.eth.getAccounts();

    await narcoCoreContract.methods.growWeed(narco.id).send({
      from: accts[0],
      value: web3.utils.toWei(growCost.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000
    }, async (err, transactonHash) => {

      if (transactonHash != null && transactonHash != undefined) {
        narco.isGrowing = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        NarcoStateWatch.instance.narcoStateDialogText = (
          <div class="title mx-auto my-auto">
            {strings.weedReady}


            <p />
            <p />

            <div class="btn btn-success mx-auto mt-5 title" onClick={() => {
              NarcoStateWatch.instance.stateDialog.hide();
            }
            }>OK!</div>

          </div>


        );
        NarcoStateWatch.instance.stateDialog.show();
        narco.isGrowing = false;


      } // end if transactonHash


    });

  }}>
    {strings.grow}
  </button>
);
export const GrowButton = growButton;

let processButton = ({ history, text }) => (
  <button class="btn btn-success mx-auto" onClick={async () => {
    var web3 = getWeb3();
    console.log("Processing: " + narco.id + " " + narco.currentDistrict + " " + refineCost);


    const accts = await web3I.eth.getAccounts();

    await narcoCoreContract.methods.refineCoke(narco.id).send({
      from: accts[0],
      value: web3.utils.toWei(refineCost.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000
    }, async (err, transactonHash) => {

      if (transactonHash != null && transactonHash != undefined) {
        narco.isProcessing = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }

        NarcoStateWatch.instance.narcoStateDialogText = (
          <div class="title mx-auto my-auto">
            {strings.cokeReady}


            <p />
            <p />

            <div class="btn btn-success mx-auto mt-5 title" onClick={() => {
              NarcoStateWatch.instance.stateDialog.hide();
            }
            }>OK!</div>

          </div>


        );
        NarcoStateWatch.instance.stateDialog.show();
        narco.isProcessing = false;


      } // end if transactonHash


    });



  }}>
    {strings.produce}
  </button>
);
export const ProcessButton = processButton;


var mobileScreenDialogStyle = {
  backgroundColor: '#606060',
  color: '#ffffff',
  textAlign: 'center',
  width: '75%',
  minHeight: '75vh',
  padding: '35px',
  borderRadius: '5px',
  boxShadow: '0 0 0 2px #000, 0 0 0 10px rgba(96, 96, 96, 0.9), 0 0 0 12px #000'
};

let recruitButton = ({ history, text, narcoId, narcoName }) => (
  <span>

    <div onClick={() => {
      //narco.isRecruiting=true;
     // window.location="http://localhost:3001?id="+narcoId+"&op=r";
      window.location = "https://narcos.doperaider.com?id=" + narcoId + "&op=r";
    }}>
      Recruit
    </div>
  </span>
);
export const RecruitButton = recruitButton;




let changeItdentityButton = ({ history, text, narcoId, narcoName, fromGenes }) => (
  <span>
    <div onClick={() => {
      //narco.isRecruiting=true;
      //window.location="http://localhost:3001?id="+narcoId+"&op=c";
      window.location = "https://narcos.doperaider.com?id=" + narcoId + "&op=c";
    }}>
      Change
    </div>
  </span>
);
export const ChangeIdentityButton = changeItdentityButton;



let showNarcoPanelButton = ({ history, text }) => (
  <div class="narcoPanelButton">

    <SkyLight
      dialogStyles={mobileScreenDialogStyle}
      hideOnOverlayClicked
      ref={ref => this.narcoPanelWideDialog = ref}
    >
      <span>

        <div class="mx-auto">
          <NarcoPanelWide />
        </div>


      </span>
    </SkyLight>

    <div onClick={() => {
      //narco.isRecruiting=true;

      this.narcoPanelWideDialog.show();

    }}>
      Narco
    </div>
  </div>
);
export const ShowNarcoPanelButton = showNarcoPanelButton;

var endingCool = [false, false, false, false, false, false, false];

let endCooldownNowButton = ({ history, text, cooldown }) => (

  <div onClick={async () => {

    var web3 = getWeb3();

    const accts = await web3I.eth.getAccounts();

    await districtsContract.methods.endCoolDownNow(narco.id, cooldown).send({
      from: accts[0],
      value: web3.utils.toWei(endCooldownCost.toString(), 'ether'),
      gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
      gas: 500000
    }, async (err, transactonHash) => {

      if (transactonHash != null && transactonHash != undefined) {
        endingCool[cooldown] = true;

        let transactionReceipt = null
        //console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          try {
            transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
            await sleep(expectedBlockTime)
          } catch (error) {
            console.log(error)
            transactionReceipt = "dummy"
          }
        }
        endingCool[cooldown] = false;
      }


    })

  }}>
    {(endingCool[cooldown] == true) ?
      <BusyButton />
      :
      <span>End Cooldown {endCooldownCost} POA</span>
    }

  </div>
);
export const EndCooldownButton = endCooldownNowButton;


let busyButton = ({ history, text }) => (
  <div><img src="images/ripple.gif" height="25px;" /></div>
);
export const BusyButton = busyButton;
