import React, { Component } from 'react';
import {Route,NavLink,HashRouter} from "react-router-dom";

import {districtClassNames} from "./GlobalConfig"

import {ShowNarcoPanelButton} from "./districts/ActionButtons"
import {narco} from './contracts/NarcoStateWatch'

import {strings , setLanguage, getLanguage} from "./LocaleStrings"

import LocalizedComponent from './LocalizedComponent'
import { ToastContainer, toast } from 'react-toastify';
import {getWeb3} from "./contracts/Contracts"

import 'react-toastify/dist/ReactToastify.css';

class Header  extends Component {

  onSetLanguage(language){
    setLanguage(language);
        this.setState({});
  }


  openPortis(){

    getWeb3().currentProvider.showPortis(() => {
    console.log('Portis window was closed by the user');
     });
  }

  render() {

    return (
       <HashRouter>


        <div class="col-12 d-flex justify-content-between">






        <div class="dropdown">

        {('en' ==  getLanguage())?<div class="mt-3 button langbutton"><a><img class="padding" src="../css/english.jpg" height="25px"/></a></div>:null}
        {('ch' ==  getLanguage())?<div class="mt-3 button langbutton"><a><img class="padding" src="../css/chinese.svg" height="28px"/></a></div>:null}
        {('de' ==  getLanguage())?<div class="mt-3 button langbutton"><a><img class="padding" src="../css/german.png" height="25px"/></a></div>:null}
        {('ru' ==  getLanguage())?<div class="mt-3 button langbutton"><a><img class="padding" src="../css/russian.png" height="28px"/></a></div>:null}



          <div class="dropdown-content">
          <div><p class="markettitle" onClick={() => {this.onSetLanguage('en') }}><img class="padding" src="../css/english.jpg" height="25px"/></p></div>
          <div><p class="markettitle" onClick={() => {this.onSetLanguage('ch') }}><img class="padding" src="../css/chinese.svg" height="28px"/></p></div>
          <div><p class="markettitle" onClick={() => {this.onSetLanguage('de') }}><img class="padding" src="../css/german.png" height="25px"/></p></div>
          <div><p class="markettitle" onClick={() => {this.onSetLanguage('ru') }}><img class="padding" src="../css/russian.png" height="28px"/></p></div>
          </div>
        </div>

        {

          (getWeb3().currentProvider.isPortis==true)?
          <div class="button portis-button">
          <div><a onClick={() => {this.openPortis() }}><img class="padding" src="images/portis.png" height="26" /></a></div>
          </div>
          :
          null

        }



        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          />
  <ToastContainer />

        </div>
      </HashRouter>
    );
  }
}

export default Header;
