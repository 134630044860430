import React, { Component } from "react";
import {getNarcoCardImage , getHomeFlagImage} from "../GlobalConfig"
import {narco} from '../contracts/NarcoStateWatch'

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

class NarcoCard extends LocalizedComponent{

  render(){

    var narco=this.props.narco;

    return(


  //  console.log("Narco: " + this.props.narco.name);
  //  console.log("Home: " + this.props.narco.homeLocation);

  
      <span>

      {(narco.id==392)?
        
        <div class="narcocardtwo" style={getNarcoCardImage(this.props.narco.genes)}>
        <table>
  
        {
          (narco.id<=300) ?
          <div><img class="originalgangster" src="../css/originalgangster.png"/></div>
          :
          null
        }
  
        <thead>
        <div colspan="2">{this.props.narco.name}</div>
        </thead>
        <tbody>
        <tr>
        <td class="respect">{this.props.narco.level}</td>
        <td class="home"  style={getHomeFlagImage(this.props.narco.homeLocation)}></td>
        </tr>
        <tr>
        <td class="respectLabel">{strings.respect}</td>
        <td class="homeLabel">{strings.home}</td>
        </tr>
  
        <tr>
        <td class="icon attack"></td>
        <td class="leftAmount">{this.props.narco.attack}</td>
        <td class="icon grow"></td>
        <td class="rightAmount">{this.props.narco.grow}</td>
  
        </tr>
  
        <tr>
        <td class="icon shield"></td>
        <td class="leftAmount">{this.props.narco.defense}</td>
        <td class="icon produce"></td>
        <td class="rightAmount">{this.props.narco.refine}</td>
  
        </tr>
  
        <tr>
        <td class="icon speed"></td>
        <td class="leftAmount">{this.props.narco.speed}</td>
        <td class="icon weed"></td>
        <td class="rightAmount">{this.props.narco.weedTotal} oz</td>
  
        </tr>
  
        <tr>
        <td class="icon bp"></td>
  
        {
          (this.props.isTarget=="true")?
          <td class="leftAmount">{this.props.narco.carry}</td>
          :
          <td class="leftAmount">{this.props.narco.carry - this.props.narco.remainingCapacity} / {this.props.narco.carry}</td>
        }
        <td class="icon coke"></td>
        <td class="rightAmount">{this.props.narco.cokeTotal} oz</td>
  
        </tr>
  
        </tbody>
        </table>
        </div>
        
        :

        <div class="narcocard" style={getNarcoCardImage(this.props.narco.genes)}>
        <table>
  
        {
          (narco.id<=300) ?
          <div><img class="originalgangster" src="../css/originalgangster.png"/></div>
          :
          null
        }
  
        <thead>
        <div colspan="2">{this.props.narco.name}</div>
        </thead>
        <tbody>
        <tr>
        <td class="respect">{this.props.narco.level}</td>
        <td class="home"  style={getHomeFlagImage(this.props.narco.homeLocation)}></td>
        </tr>
        <tr>
        <td class="respectLabel">{strings.respect}</td>
        <td class="homeLabel">{strings.home}</td>
        </tr>
  
        <tr>
        <td class="icon attack"></td>
        <td class="leftAmount">{this.props.narco.attack}</td>
        <td class="icon grow"></td>
        <td class="rightAmount">{this.props.narco.grow}</td>
  
        </tr>
  
        <tr>
        <td class="icon shield"></td>
        <td class="leftAmount">{this.props.narco.defense}</td>
        <td class="icon produce"></td>
        <td class="rightAmount">{this.props.narco.refine}</td>
  
        </tr>
  
        <tr>
        <td class="icon speed"></td>
        <td class="leftAmount">{this.props.narco.speed}</td>
        <td class="icon weed"></td>
        <td class="rightAmount">{this.props.narco.weedTotal} oz</td>
  
        </tr>
  
        <tr>
        <td class="icon bp"></td>
  
        {
          (this.props.isTarget=="true")?
          <td class="leftAmount">{this.props.narco.carry}</td>
          :
          <td class="leftAmount">{this.props.narco.carry - this.props.narco.remainingCapacity} / {this.props.narco.carry}</td>
        }
        <td class="icon coke"></td>
        <td class="rightAmount">{this.props.narco.cokeTotal} oz</td>
  
        </tr>
  
        </tbody>
        </table>
        </div>

      }



      </span>
    );
  }
}

export default NarcoCard;
