import React, { Component } from "react";
import {getBackgroundImage,getCharacterImage , percentageWidthStyle , CURRENCY_SYMBOL} from "../GlobalConfig"
import {GrowButton , ProcessButton, BusyButton , EndCooldownButton} from "./ActionButtons";
import {ActionAnimation} from "./ActionAnimation"

import {growCost , refineCost, narco} from '../contracts/NarcoStateWatch'
import { strings } from "../LocaleStrings";

function getGrowYield(narc){
      var maxYield = 9 + narc.grow; // max amount can be grown based on skill
      var y = Math.min(narc.seeds,maxYield);
      return parseInt(y);
}

function getRefineYield(narc){
      var maxYield = 3+(narc.refine/3); // max amount can be refined based on skill
      var y = Math.min(narc.chemicals,maxYield);
      return parseInt(y);
}

class Laboratory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      narco: narco
    }
  }

  componentDidMount(){
        this.updateState();
        this.interval = setInterval(this.updateState.bind(this), 5000)
     }

    componentWillUnmount(){
           console.log("Clearing interval for Laboratory.");
           clearInterval(this.interval)
    }

   updateState(){
      this.setState({
            narco: narco
      })


      //console.log()
   }


  render() {

    return (

<div class="row h-100 col-12">

<div class="col-6 h-50 my-auto">

                  <table class="table lab mobilelab table-sm h-75 w-75 mx-auto">

                  <thead>
                  <tr>
                  <td colspan="2"><div class="markettitle mb-3">{strings.gw}</div></td>
                  </tr>
                  </thead>


                  <tbody>
                      <tr class="text-center">
                        <td colspan="1"><img src="../css/images/seeds.png" height="30px"/><p class={(this.state.narco.seeds==0)? "text-danger" : null}>{this.state.narco.seeds} {strings.seeds}</p></td>
                        <td colspan="1"><img src="../css/images/grow.png" height="30px"/><p>{this.state.narco.grow} {strings.gs}</p></td>
                      </tr>

                      {(narco.growCooldownProgress==100)?
                         <tr>
                         <td colspan="1"><p class="title">{strings.my}</p> <p>{getGrowYield(narco)} {strings.ounces}</p></td>

                        <td colspan="1"><p class="title">{strings.cost}</p> <p>{growCost} {CURRENCY_SYMBOL}</p></td>
                        </tr>
                        :

                        <tr>

                        <td colspan="2">
                        <div class="title text-center mb-3">{strings.clean} {narco.growCooldownProgress}%</div>
                          <div class="progress w-75 mx-auto mb-3">

                            <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={percentageWidthStyle(narco.growCooldownProgress)} ></div>
                          </div>
                          <p/>
                          <div class="btn btn-success">
                            <EndCooldownButton cooldown={1}/>
                          </div>
                          </td>
                        </tr>

                    }

                    {(narco.isGrowing==true)?
                      <tr>
                      <td colspan="2">
                      <div class="btn mx-auto"><BusyButton />
                      </div>
                      </td>
                      </tr>
                   :
                   <tr>
                        {
                          (narco.seeds>0 && narco.currentDistrict==narco.homeLocation && narco.growCooldownProgress==100) ?


                          <td colspan="2">
                          <GrowButton />
                          </td>

                          :
                          <td colspan="2">
                          <div class="mx-auto"><p>{strings.togrow}</p></div>
                          </td>

                        }
                  </tr>
                  }
                      </tbody>
                      </table>





</div>


<div class="col-6 h-50 my-auto">



                  <table class="table lab mobilelab table-sm h-75 w-75 mx-auto">

                  <thead>
                  <tr>
                  <td colspan="2"><div class="markettitle mb-3">{strings.proc}</div></td>
                  </tr>
                  </thead>


                  <tbody>
                      <tr class="text-center">
                        <td colspan="1"><img src="../css/images/chemicals.png" height="30px"/><p class={(this.state.narco.chemicals==0)? "text-danger" : null}>{this.state.narco.chemicals} {strings.chems}</p></td>
                        <td colspan="1"><img src="../css/images/lab.png" height="30px"/><p>{this.state.narco.refine} {strings.ps} </p></td>
                      </tr>


                      {(narco.refineCooldownProgress==100)?
                        <tr>
                        <td colspan="1"><p class="title"> {strings.my} </p> <p>{getRefineYield(narco)} {strings.ounces}</p></td>

                       <td colspan="1"><p class="title"> {strings.cost}
                       </p> <p>{refineCost} {CURRENCY_SYMBOL}</p></td>
                       </tr>
                        :

                        <tr>

                        <td colspan="2">
                        <div class="title text-center mb-3">cleaning lab equipment: {narco.refineCooldownProgress}%</div>
                          <div class="progress w-75 mx-auto mb-3">

                            <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={percentageWidthStyle(narco.refineCooldownProgress)} ></div>
                          </div>

                          <p/>
                          <div class="btn btn-success">
                            <EndCooldownButton cooldown={2}/>
                          </div>
                          </td>
                        </tr>

                    }

                    {(narco.isProcessing==true)?
                      <tr>
                      <td colspan="2">
                      <div class="btn mx-auto"><BusyButton />
                      </div>
                      </td>
                      </tr>
                   :
                   <tr>
                        {
                          (narco.chemicals>0 && narco.currentDistrict==narco.homeLocation && narco.refineCooldownProgress==100) ?


                          <td colspan="2">
                          <ProcessButton />
                          </td>

                          :
                          <td colspan="2">
                          <div class="mx-auto"><p> {strings.toprod}</p></div>
                          </td>

                        }
                  </tr>
                  }
                      </tbody>
                      </table>



</div>
            </div>

    );
  }
}

export default Laboratory;
