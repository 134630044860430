import React, { Component } from 'react';

import SelectNarco from "./SelectNarco"
import Game from "./Game"
import NarcoInstance from "./narco/NarcoInstance"
class ApplicationRoutes extends Component {

  componentWillMount(){
        this.updateState();
        this.interval = setInterval(this.updateState.bind(this), 2000)
     }
   updateState(){

     this.setState({
         currentNarcoId: NarcoInstance.currentNarcoId,
     })
   }

  render() {

    if (this.state.currentNarcoId==0) {return (<SelectNarco/>);}

    clearInterval(this.interval)
    return (<Game/>);
  }
}

export default ApplicationRoutes;
